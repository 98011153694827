import { ComponentPropsWithoutRef, ReactNode } from "react";
import { DefaultDialog } from "../default/DefaultDialog";
import XIcon from "@assets/icon/x.svg?react"
import { cn } from "@shared/utils/tailwind-merge";

interface DialogWithHeaderProps extends Omit<ComponentPropsWithoutRef<"div">, "title">{
  title?: string | ReactNode;
  // children?: ReactElement;
  modalAccess: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen : boolean;
}


export function DialogWithHeader ({title, modalAccess, isOpen, children, className,  ...props}: DialogWithHeaderProps){
  return (
    <DefaultDialog isOpen={isOpen} modalAccess={modalAccess}>
      <div 
        className={cn(
          "bg-secondary-base text-white basis-1/2 rounded-md flex flex-col max-h-full",
          className,
        )} 
        {...props}
      >
        <div className="flex justify-between p-2">
          <h4 className="text-gray-400 w-full">{title}</h4>
          <button 
            onClick={() => modalAccess(false)}
            className="hover:text-gray-500 active:text-gray-600"
          >
            <XIcon width={25} height={25} />
          </button>
        </div>
        <section className="p-2 overflow-auto">
          {children}
        </section>
      </div>
    </DefaultDialog>
  )
}