import sampleImage from "./../../assets/image-sample.png";
import noteImage from "./../../assets/image-note.png";
import decryptImage from "./../../assets/image-decrypt.png";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { useRef, useState } from "react";
import { UploadSampleFileModal } from "../upload-sample-file-modal/UploadSampleFileModal";
import { FileWithPath } from "react-dropzone";
import { AgreementModal } from "../agreement-modal/AgreementModal";
import { UploadHackerNoteFileModal } from "../upload-hacker-file-modal/UploadHackerFileModal";
import { HackerNoteTooltip, SampleFileTooltip } from "../components/Tooltip";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@shared/model/hooks";
import { processNoteAndSample, processNoteAndSampleNew } from "../../model/action";
import { selectTask } from "@entities/task";
import { useTranslation } from "react-i18next";
import { GenerateToasrContent } from "@shared/components/toast";
import { ExtraDataModal, ExtraTaskFieldMethodAccess } from "../extra-data-modal/ExtraDataModal";
import { captureException } from "@sentry/react";
import { SelectVirusModal } from "@features/virus/select-virus/ui/modal-select-virus/SelectVirusModal";


/**
 * 
 * @todo choose wheter using props drill or ref.
 */
export function SelectNoteAndSample(){
  const [isModalUploadSampleFileOpen, setModalUploadSampleFile] = useState<boolean>(false);
  const [isModalHackerNoteFileOpen, setModalHackerNoteFile] = useState<boolean>(false);
  const [isModalAgreementOpen, setModalAgreement] = useState<boolean>(false);
  const [isModalExtraOpen, setModalExtraOpen] = useState(false);
  const [isModalSelectVirusOpen, setModalSelectVirusOpen] = useState(false);

  // -- file --
  const [sampleFiles, setSampleFiles] = useState<FileWithPath[]>([]);
  const [hackerNoteFiles, setHackerNoteFiles] = useState<FileWithPath[]>([]);


  // -- extra data --

  const currentTask = useAppSelector(selectTask);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {t} = useTranslation();


  // replacement for useref
  const methodRef = useRef<ExtraTaskFieldMethodAccess>(null);



  async function uploadSampleAndHackerNote(){
    try {

      // show modal virus when active task not initiliazed
      if(!currentTask?.id){
        // toast.warning(t("features.task.select_note_and_sample.message.should_scan_first"));
        // return;
        setModalSelectVirusOpen(true);
        return;
      }

      if(!methodRef.current) return;

      const data = methodRef.current.getFormValue();
      // console.log(data);

      console.log(sampleFiles);
      console.log(hackerNoteFiles);

      const result = await dispatch(processNoteAndSample({
        
        hackerNote: hackerNoteFiles,
        sampleFile: sampleFiles,
        // @ts-expect-error it should not error
        extra: data
      })).unwrap();

      // navigate("/scan");
    } catch (error) {
      captureException(error);
      // console.error(error);
      toast.error(GenerateToasrContent((error as Error).message));
      // toast.error(t("features.task.select_note_and_sample.message.error_when_send_data"));
    }
  }

  async function uploadSampleAndHanckerNoteNew(virusName: string){
    try {

      if(!methodRef.current) return;

      const data = methodRef.current.getFormValue();
      // console.log(data);

      console.log(sampleFiles);
      console.log(hackerNoteFiles);

      const result = await dispatch(processNoteAndSampleNew({
        virus: virusName,
        hackerNote: hackerNoteFiles,
        sampleFile: sampleFiles,
        // @ts-expect-error it should not error
        extra: data
      })).unwrap();

      // navigate("/scan");
    } catch (error) {
      captureException(error);
      // console.error(error);
      toast.error(GenerateToasrContent((error as Error).message));
      // toast.error(t("features.task.select_note_and_sample.message.error_when_send_data"));
    }
  }

  // const isDisabled = Boolean(sampleFiles.length === 0 || hackerNoteFiles.length === 0);
  const isDisabled = Boolean(sampleFiles.length === 0);

  return(
  <div className="flex justify-center  gap-10">

    {/* upload infected sample section */}
    <div className="max-w-[250px] relative shrink-0 flex flex-col">
      <span className="absolute top-0 left-0 text-2xl text-warning">
        2
      </span>
      <SampleFileTooltip />
      <img src={sampleImage as string} alt="" width={100} className="mx-auto" />
      <h3 className="text-center text-3xl">
        {t("features.task.select_note_and_sample.select_note_and_sample.infected_sample")}
      </h3>
      <p className="grow text-center font-light text-gray-400">
        {t("features.task.select_note_and_sample.select_note_and_sample.infected_sample_description")}
      </p>
      <SolidButton 
        onClick={() => setModalUploadSampleFile(true)}
        className="block mx-auto mt-2"
        variant={sampleFiles.length !== 0 ? "base" : "primary"}
      >
        {hackerNoteFiles.length >= 0 
          ? t("features.task.select_note_and_sample.select_note_and_sample.select_file") 
          : t("features.task.select_note_and_sample.select_note_and_sample.uploaded")
        }
      </SolidButton>
    </div>

    {/* upload hacker note or anydeks credentials */}
    <div className="max-w-[250px] relative shrink-0 flex flex-col">
      <span className="absolute top-0 left-0 text-2xl text-warning">
        3
      </span>
      <HackerNoteTooltip />
      <img src={noteImage as string} alt="" width={100} className="mx-auto" />
      <h3 className="text-center text-3xl">
        {t("features.task.select_note_and_sample.select_note_and_sample.ransomware_note")}
      </h3>
      <p className="grow text-center font-light text-gray-400">
        {t("features.task.select_note_and_sample.select_note_and_sample.ransomware_note_description")}
      </p>
      <SolidButton
        onClick={() => setModalHackerNoteFile(true)} 
        className="block mx-auto mt-2"
        variant={hackerNoteFiles.length !== 0 ? "base" : "primary"}
      >
        {hackerNoteFiles.length >= 0
          ? t("features.task.select_note_and_sample.select_note_and_sample.select_file") 
          : t("features.task.select_note_and_sample.select_note_and_sample.uploaded")
        }
      </SolidButton>
    </div>

    {/* agreement modal and another information section */}
    <div className="max-w-[250px] relative shrink-0 flex flex-col">
      <span className="absolute top-0 left-0 text-2xl text-warning">
        4
      </span>
      <img src={decryptImage as string} alt="" width={100} className="mx-auto" />
      <h3 className="text-center text-3xl whitespace-nowrap">
        {t("features.task.select_note_and_sample.select_note_and_sample.analyst_and_decrypt")}
      </h3>
      <p className="grow text-center font-light text-gray-400">
        {t("features.task.select_note_and_sample.select_note_and_sample.analyst_and_decrypt_description")}
      </p>
      <SolidButton 
        disabled={isDisabled}
        onClick={isDisabled 
          ? ()=>{ toast.error("Harap pilih sample file dan Teks Teror atau ID/Pass Anydesk") } 
          : () => setModalExtraOpen(true)
        } // when disable run empty function
        className="block mx-auto mt-2"
      >
        Analisis & Decrypt
      </SolidButton>
    </div>

    {/* # --- Modal --- */}
    <UploadSampleFileModal 
      isOpen={isModalUploadSampleFileOpen}
      modalAccess={setModalUploadSampleFile}
      filesValue={sampleFiles}
      setFilesValue={setSampleFiles}
    />
    <UploadHackerNoteFileModal
      isOpen={isModalHackerNoteFileOpen}
      modalAccess={setModalHackerNoteFile}
      filesValue={hackerNoteFiles}
      setFilesValue={setHackerNoteFiles}
    />
    <AgreementModal
      isOpen={isModalAgreementOpen}
      modalAccess={setModalAgreement}
      onSubmitSample={uploadSampleAndHackerNote}
    />

    {/* use ref here... */}
    <ExtraDataModal
      ref={methodRef}
      nextModal={() => setModalAgreement(true)}
      isOpen={isModalExtraOpen}
      modalAccess={setModalExtraOpen}
    />

    {/* optional modal when user haven't indexing */}
    <SelectVirusModal 
      onSubmit={uploadSampleAndHanckerNoteNew} 
      isOpen={isModalSelectVirusOpen} 
      setIsOpen={setModalSelectVirusOpen}
    />
  </div>
  )
}