import { 
  AnalystPaymentAction, 
  AnalystPaymentConfirm, 
  AnalystPaymentInit, 
  AnalystResult, 
  AnalystSurveyConfirm, 
  AnalystSurveyInit, 
  selectCurrentStage, 
  selectTask,
  Task,
  TaskAnalystType
} from "@entities/task";
import { CardWithDate } from "@shared/components/card";
import { useAppSelector } from "@shared/model/hooks";
import { AnimatePresence } from "framer-motion";
import { ProcessWrapperStyle } from "@process/task-process/components";
import { useTranslation } from "react-i18next";
import { AnalystPreview } from "@features/task/analyst-preview-payment";
import { SelectNoteAndSample } from "@features/task/select-note-and-sample";
import { SelectedType } from "@entities/task/stage/analyst/select-type";


export function AnalystProcess(){
  const currentStage = useAppSelector(selectCurrentStage);
  const task = useAppSelector(selectTask)
  const { t } = useTranslation();

  // if(currentStage === "ANALYST_PAYMENT_CONFIRM") return <SelectNoteAndSample />

  return (
    <CardWithDate 
      date={new Date()} 
      title={t("process.task_process.analyst_process.title")}
      className="h-full"
    >
      <AnimatePresence>
        <ProcessWrapperStyle key={currentStage}>
          {currentStage === "SELECTED_ANALYST_TYPE" && <SelectedType />}
          {currentStage === "ANALYST_PAYMENT_CONFIRM" && <AnalystPaymentConfirmSection task={task} />}
          {currentStage === "ANALYST_PAYMENT_INIT" && <AnalystPaymentInitSection task={task} />}
          {currentStage === "ANALSYT_PAYMENT_ACTION" && <AnalystPaymentAction />}
          
          {currentStage === "ANALYST_RESULT" && (
            <AnalystResult 
              task={task} 
              analystPreviewSlot={ 
              <AnalystPreview task={task} />
              }  
            />
          )}
        </ProcessWrapperStyle>
      </AnimatePresence>
    </CardWithDate>
  )
}

function AnalystPaymentInitSection({task}: {task: Task}){

  if(task.analystType === TaskAnalystType.FREE) return (
    <AnalystSurveyInit  
      analystPreviewSlot={ <AnalystPreview task={task} />} 
      task={task}
    />
  )

  return (
    <AnalystPaymentInit 
    analystPreviewSlot={ <AnalystPreview task={task} />} 
    task={task} />
  )

}


function AnalystPaymentConfirmSection({task}: {task: Task}){
  if(task.analystType === TaskAnalystType.FREE) return (
    <AnalystSurveyConfirm />
  )

  return (
    <AnalystPaymentConfirm />
  )
}