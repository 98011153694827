import { SolidButton } from "@shared/components/form/button/SolidButton";
import { useEffect, useState } from "react";
import { FreeAnalystSurveyModal } from "./SurveyModal";
import { answerSurvey, getMySurveyAnswer, getSurveyByType, getSurveyContent } from "@services/www.fixransomware.com/survey";
import { useAppDispatch, useAppSelector } from "@shared/model/hooks";
import { selectSurveyState, setSurveyIsAlreadyAnsewer, SurveyContent, SurveyContentType } from "@entities/survey";
import { SendQuestion } from "../model/types";
import GiftIcon from "@assets/icon/gift.svg?react"
import clsx from "clsx";
import i18next from "i18next";
import { toast } from "react-toastify";
import { sendAnalystFreeSurvey } from "../model/action";
import { selectTask, TaskAnalystType } from "@entities/task";
import { useTranslation } from "react-i18next";


const FreeAnalystTypeByLanguage = {
  id: SurveyContentType.FREE_ANALYST_QUALIFICATION,
  en: SurveyContentType.FREE_ANALYST_QUALIFICATION_EN
} as const;




export function FreeAnalystSurveyButton(){

  const [isModalOpen, setIsOpenModalOpen] = useState(false);
  const [surveyData, setSurveyData] = useState<SurveyContent | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const task = useAppSelector(selectTask);

  const {t, i18n} = useTranslation();

  const dispatch = useAppDispatch();

  useEffect(() =>{
    showSurveyService();
  },[]);




  async function sendAnswer(props: SendQuestion[]){

    if(!surveyData?.id) {
      toast.error("survey data not defined");
      return;
    }

    const data = {
      surveyContentId: surveyData.id, 
      answer: props
    }

    if(surveyData?.questions?.length !== props.length){
      /**@todo move it to translation file */
      toast.warn(i18n.resolvedLanguage === "id" 
        ? "Tolong isi semua survey"
        : "Please fill all of the survey"
      );
    }

    // console.log(props);
    
    // console.log(surveyData);
    // console.log(data);

    await dispatch(sendAnalystFreeSurvey(data)).unwrap();
    // console.log(result);
    dispatch(setSurveyIsAlreadyAnsewer(true));
  }

  async function showSurveyService(){
    if(i18next.resolvedLanguage == "id"){
      const surveyContent = await getSurveyByType(FreeAnalystTypeByLanguage.id);
      setSurveyData(surveyContent);
      setIsLoading(false);
      return;
    }
    const surveyContent = await getSurveyByType(FreeAnalystTypeByLanguage.en);
    setSurveyData(surveyContent);
    setIsLoading(false);
    return;
    
  }

  if(isLoading) return (
    <SolidButton className="py-2" onClick={() => {}}>
      Loading.....
    </SolidButton>
  )


  if(!surveyData) return (    
    <SolidButton disabled className="py-2" onClick={() => {}}>
      No Survey
    </SolidButton>
  )

  return (
    <>

      {/* show when user haven't take the survey. will open the survey modal when you click it */}
      <SolidButton 
        className={clsx(
          "py-2 px-5 flex justify-between items-center"
         )}
        onClick={() => setIsOpenModalOpen(!isModalOpen)}
      >
        <GiftIcon className="w-5  h-5 shrink-0" />
        {t("features.task.take_survey_for_analyst_term.survey_button")}
      </SolidButton>

      {/* the survey modal */}
      <FreeAnalystSurveyModal 
        surveyData={surveyData}
        onSendSurvey={sendAnswer}  
        isOpen={isModalOpen} 
        modalAccess={setIsOpenModalOpen}  
      />
    </>
  )
}