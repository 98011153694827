import { Task } from "@entities/task";
import { Timer } from "@shared/components/timer";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";



interface DecryptEstimationProps {
  task: Task;
}
export function DecryptEstimation(props: DecryptEstimationProps){

  const { t } = useTranslation();

  const deadline = useMemo(()=>{
    if(!props.task.createdAt!) return new Date().toISOString();
    let createdDate = new Date(props.task.createdAt!);

    // deadline = createdat data + estiated day
    // it's when the deadlien is counted by days count;
    if(props.task.estimatedDecrypt){
      createdDate.setDate(createdDate.getDate() + Number(props.task.estimatedDecrypt ?? 0)); // prevent error when estimatedDecypt is false
    
      // when the date is directly assign
    }else if(props.task.estimatedDecryptFinish){
      createdDate = new Date(props.task.estimatedDecryptFinish);
    }


    return createdDate.toISOString();
  }, [props.task])

  // console.log(deadline)
  return (
  <div className="p-10">
    <p className="text-gray-400 text-center">
    {t("entities.task.stage.decrypt.decrypt_estimation")}
    </p>
    <div className="flex justify-center h-[80%] items-center">
      <Timer deadline={deadline} />
    </div>
  </div>
  )
}