


export interface Option{
  id: string;
  placeholder: string;
  type: "text" | "input";
}

export interface Question {
  id: string;
  question: string;
  option: Option[];
}


export interface SendQuestion {
  questionId: string;
  answerId: string;
  answerType: "text" | "input";
  answerValue: string;
}


export enum SurveyStage {
  SURVEY = 1,
  GIFT
}

