import { ChangeEvent, ComponentPropsWithoutRef, ForwardedRef, forwardRef, useImperativeHandle, useState } from "react";
import { AttackedDeviceOption } from "../..";
import { useTranslation } from "react-i18next";
import { DefaultDropzone, TextAreaInputDark, TextInputDark } from "@shared/components/form";
import { SelectDark } from "@shared/components/form/select";
import { DialogWithHeader } from "@shared/components/dialog";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { toast } from "react-toastify";
import { FileInput } from "@shared/components/form/input/FileInput";
import { FileInputDark } from "@shared/components/form/input/FileInputDark";
import { MIN_CHRONOLOGY_CHAR_COUNT } from "../../constant";



export type ExtraTaskFieldData = {
  attackedDevice : {
    deviceType : string;
    value : string;
  },
  dataEstimation : number,
  affectedDevice : number,
  topologyFile: File
}

export interface ExtraTaskFieldMethodAccess {
  getFormValue: () => ExtraTaskFieldData;
}


export interface ExtraDataProps extends Omit<ComponentPropsWithoutRef<typeof DialogWithHeader>, "title"> {
  nextModal: ()=> any;
}


export interface ExtraTaskFieldMethodAccess {
  getFormValue: () => ExtraTaskFieldData;
}

export const ExtraDataModal = forwardRef((props: ExtraDataProps, ref: ForwardedRef<ExtraTaskFieldMethodAccess>) => {

  const [topologyFile, setTopologyFile] = useState<File[]>([]);
  // initially other
  const [attackedDeviceType, setAttackedDeviceType] = useState<typeof AttackedDeviceOption[number]>(AttackedDeviceOption[5]);
  // initially from other value
  const [attackedDeviceValue, setAttackedDeviceValue] = useState<string>(AttackedDeviceOption[5].label);
  // specific for attacked device, because it needed to be valid reason
  const [attackedDeviceValueError, setAttackedDeviceValueError] = useState({isError: true, message: "Min 10 character for chronology infection"})

  const [dataEstimation, setDataEstimation] = useState<number>(0);
  const [affectedDevice, setAffectedDevice] = useState<number>(0);

  const { t } = useTranslation();


  // access this component value from modal or parent component
  useImperativeHandle(ref, () =>({
    getFormValue(): ExtraTaskFieldData{
      return {
        attackedDevice : {
          deviceType : attackedDeviceType.value,
          value : attackedDeviceValue
        },
        dataEstimation : dataEstimation,
        affectedDevice : affectedDevice,
        topologyFile: topologyFile[0]
      }
    }
  }))

  function handleOnAttackedDeviceValueChange(e: ChangeEvent<HTMLTextAreaElement>){
    if(e.target.value?.length < MIN_CHRONOLOGY_CHAR_COUNT){
      setAttackedDeviceValueError({isError: true, message: "Min 10 character for chronology infection"});
    }else{
      setAttackedDeviceValueError({isError: false, message: ""});
    }
    setAttackedDeviceValue(e.target.value);
  }

  function handleNextModal(){
    if(attackedDeviceValueError?.isError){
      toast.warn(attackedDeviceValueError.message);
      // toast.warn("ada isu dengan formnya, silahkan di cek ulang");
      return;
    }
    props.modalAccess(false); // close this modal

    // go to next modal
    props.nextModal();
  }

  return (
    <DialogWithHeader
      isOpen={props.isOpen}
      modalAccess={props.modalAccess}
      title={t("features.task.select_note_and_sample.agreement_modal.title")}
    > 
    <div className="p-2 gap-1">
      {/* <span className="text-warning/50">*optional</span> */}
      <div className="mb-5 flex flex-col gap-3">
        
        {/* attacked device type */}
        <div className="">
          <label className="text-gray-400">{t("features.task.select_note_and_sample.agreement_modal.form.attacked_device")}</label>
          <SelectDark
            value={attackedDeviceType}
            onChange={(data) => {
              setAttackedDeviceType(data as typeof AttackedDeviceOption[number]);
              setAttackedDeviceValue((data as typeof AttackedDeviceOption[number]).label);
            }}
            name="attackedDevice"
            className="w-full text-gray-300"
            options={AttackedDeviceOption}
          />
        </div>
        
        {/* attacked device value ot cronology */}
        <div className="">
          <TextAreaInputDark 
            placeholder={t("features.task.select_note_and_sample.agreement_modal.form.chronology")}
            onChange={handleOnAttackedDeviceValueChange}
          />
          {attackedDeviceValueError.isError && (
            <p className="text-xs text-red-400">{attackedDeviceValueError.message}</p>
          )}
          <small className="text-gray-400">
            {t("features.task.select_note_and_sample.agreement_modal.form.chronology_example")}
          </small>
        </div>
        {/* <div className="">
          <TextInputDark 
            className="w-full" 
            name="affectedDeviceValue" 
            placeholder={t("features.task.select_note_and_sample.agreement_modal.form.chronology")}
            type="text" 
            onChange={handleOnAttackedDeviceValueChange}
          />
          {attackedDeviceValueError.isError && (
            <span className="text-xs text-red-400">{attackedDeviceValueError.message}</span>
          )}
        </div> */}
 
        {/* data estimation in gb */}
        <div className="">
          <label className="text-gray-400">
            {t("features.task.select_note_and_sample.agreement_modal.form.data_estimation")}
          </label>
          <TextInputDark 
            className="w-full" 
            name="dataEstimation" 
            placeholder={"GB"}
            type="number" 
            onChange={(e) => setDataEstimation(Number(e.target.value))}
          />
        </div>

        {/* affected device count */}
        <div className="">
          <label className="text-gray-400">
            {t("features.task.select_note_and_sample.agreement_modal.form.affected_device")}
          </label>
          <TextInputDark
            className="w-full" 
            name="affectedDevice" 
            placeholder = "Unit"
            type="number" 
            onChange={(e) => setAffectedDevice(Number(e.target.value))}
          />
        </div>
      </div>

      {/* <div className="">
        <span className="text-warning/50">
          {t("features.task.select_note_and_sample.agreement_modal.form.structure_topology")}
        </span> */}

        {/* <FileInputDark  maxCount={1} maxSize={2_000_000} onChange={(e) => e ? setTopologyFile(Array.from(e)) : ""} /> */}

        {/* <DefaultDropzone
          className="mb-3"
          filesValue={topologyFile}
          setFilesValue={setTopologyFile}
          maxCount={1}
          maxSize={2_000_000}
          whenDragText={t("features.task.select_note_and_sample.agreement_modal.form.structure_topology_upload.when_drag")}
          whenIdleText={t("features.task.select_note_and_sample.agreement_modal.form.structure_topology_upload.when_idle")}
        /> */}
      {/* </div> */}
    </div>
    <div className="flex justify-end p-3">
      <SolidButton
        variant="primary"
        className="self-end"
        onClick={handleNextModal}
      >
        Analisis & Decrypt ( 1 / 2 )
      </SolidButton>
    </div>
  </DialogWithHeader>
  )
})