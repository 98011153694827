import { ReactNode } from "react";





export interface OfferListProps {
  icon: ReactNode;
  children: ReactNode;
}



export function OfferList (props: OfferListProps){

  return (
    <div className="flex gap-4">
      {props.icon}
      <p className="font-semibold">{props.children}</p>
    </div>
  )
}