import { clearTask } from "@entities/task";
import { resetVirus } from "@entities/virus";
import { useAppDispatch } from "@shared/model/hooks";
import { ComponentPropsWithoutRef, ReactNode } from "react";
import { useNavigate } from "react-router-dom";




interface CreateNewTaskWrapperProps extends ComponentPropsWithoutRef<"div"> {
  children: ReactNode;
}



export function CreateNewTaskWrapperProps({children, ...props} : CreateNewTaskWrapperProps){

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function handleOnCliceCreateNewTask(){
    dispatch(resetVirus());
    dispatch(clearTask());

    navigate("/home");
  }

  return (
    <div onClick={handleOnCliceCreateNewTask} {...props}>
      {children}
    </div>
  )
}