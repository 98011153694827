import { Option, Question, SendQuestion } from "@features/survey/take-survey/model/types";
import { cn } from "@shared/utils/tailwind-merge";
import { ChangeEvent, useEffect, useId, useState } from "react";




interface SurveyQuestionProps {
  onSelectQuestion: (payload:SendQuestion) => void;
  // handleInputSelectChange: (index: number, value: string) => void;
  question: Question;
  answerQuestion: SendQuestion
}




export function SurveyQuestion(props:SurveyQuestionProps ){

  const [selectedAnswer, setSelectedAnswer] = useState<number | undefined>();

  useEffect(()=>{
    const selectedIndex = props.question.option.findIndex(arr => arr.id === props?.answerQuestion?.answerId);
    setSelectedAnswer(selectedIndex);
  },[props.question.id])

  function selectAnswer(index: number, value?: string){
    setSelectedAnswer(index);
    props.onSelectQuestion({
      questionId: props.question.id, 
      answerId: props.question.option[index].id,
      answerType: props.question.option[index].type,
      answerValue:value ??  props.question.option[index].placeholder
    });
  }

  function handleSelectInputChange(index: number, value: string){
    if(selectedAnswer !== index) return;
    props.onSelectQuestion({
      questionId: props.question.id, 
      answerId: props.question.option[index].id,
      answerType: props.question.option[index].type,
      answerValue:value
    });


  }


  return (
    <div className="">
      <div className="mb-2" dangerouslySetInnerHTML={{__html : props.question.question}} />
      <ul className="flex flex-col gap-2 ">
        {props.question.option.map((option, index) => (
          <li key={option.id} >
            {option.type === "text" && (
              <button 
                onClick={() => selectAnswer(index)}
                className={cn(
                  "shadow w-full text-start hover:bg-gray-400 active:bg-white",
                  " active:text-secondary-dark shadow-white p-1 px-4 rounded",
                  selectedAnswer === index && "bg-gray-400"
                )}
              >
                {option.placeholder}
              </button>
            )}

            {option.type === "input" && (
              <SelectWithInput 
                index={index}
                onInputChange={handleSelectInputChange}
                onSelect={selectAnswer}
                selectedAnswer={selectedAnswer}
                option={option}
              />
            )}



          </li>
        ))}
      </ul>
    </div>
  )
}

interface selectWithInputProps {
  onSelect: (index: number, value: string) => void;
  onInputChange: (index: number, value: string) => void;
  index: number;
  selectedAnswer?: number;
  option: Option;
}

function SelectWithInput(props: selectWithInputProps){

  const id = useId();
  const [inputValue, setInputValue] = useState("");

  function handleSelect(){
    props.onSelect(props.index, inputValue ?? undefined)
  }

  function handleInputChange(e: ChangeEvent<HTMLInputElement>){
    props.onInputChange(props.index, e.target.value);
    setInputValue(e.target.value);
  }
  
  return (
    <label
      htmlFor={id}
      className={cn(
        "shadow w-full text-start  hover:bg-gray-400 active:bg-white block",
        " active:text-secondary-dark shadow-white p-1 py-3  px-4 rounded",
        props.selectedAnswer === props.index && "bg-gray-400"
      )}
    >
      <button onClick={handleSelect} className="w-full text-start mb-1">
        {props.option.placeholder}
      </button>
      <input 
        className="w-full border-b bg-secondary-base  text-white pb-2 px-2"
        id={id} 
        value={inputValue} 
        onChange={handleInputChange} 
      />
    </ label>
  )
}