import { selectUser } from "@entities/user"
import { useAppSelector } from "@shared/model/hooks"





export function UserProfileCard(){

  const user = useAppSelector(selectUser);

  return (
    <div className="bg-secondary-dark p-5 rounded">
      <h1 className="text-2xl font-bold mb-5">
        {user?.fullname}
      </h1>

      <div className="flex">
        <div className="basis-1/3 ">Id</div>
        <div className="basis-2/3 font-semibold text-green-500">{user?.id}</div>
      </div>
      <div className="flex">
        <div className="basis-1/3 ">Email</div>
        <div className="basis-2/3 font-semibold">{user?.email}</div>
      </div>
      <div className="flex">
        <div className="basis-1/3 ">Phone Number</div>
        <div className="basis-2/3 font-semibold">{user?.phoneNumber}</div>
      </div>
      <div className="flex">
        <div className="basis-1/3 ">Institution</div>
        <div className="basis-2/3 font-semibold">{user?.institution}</div>
      </div>
      <div className="flex">
        <div className="basis-1/3 ">Address</div>
        <div className="basis-2/3 font-semibold">{user?.address}</div>
      </div>
      <div className="flex">
        <div className="basis-1/3 ">Type</div>
        <div className="basis-2/3 font-semibold">{user?.type}</div>
      </div>
    </div>
  )
}