import { DialogWithHeader } from "@shared/components/dialog";
import { ComponentPropsWithoutRef, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import qrisImg from "@assets/img/qris.png";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { TranferDataType } from "..";
import { useTranslation } from "react-i18next";


interface MakeDonationModalProp extends Omit<ComponentPropsWithoutRef<typeof DialogWithHeader>, "title"> {
  getFileModalOpen: () => any;
  setTransferData: (arg: TranferDataType) => any;
}



export function MakeDonationModal({getFileModalOpen, isOpen, modalAccess, setTransferData, ...props}: MakeDonationModalProp){
  const [nominal, setNominal] = useState("0");
  const [currency, setCurrency ] = useState<"idr" | "usd">("idr");
  const [paymentMethod, setPaymentMethod] = useState<"BNI" | "QRIS">("BNI"); 
  const { t } = useTranslation();


  function handleClickUploadFile(){
    setTransferData({
      nominal: nominal,
      paymentMethod: paymentMethod
    });

    getFileModalOpen()
  }

  return (
    <DialogWithHeader
      isOpen={isOpen}
      modalAccess={modalAccess}
      className="basis-5/12"
      title="Donation"
      {...props}
    >
      <div className="p-3">
        <div className="flex mb-2 items-center justify-between">
          <p className="text-lg font-semibold">{t("features.donation.make_donation.make_donation_modal.select_currency")}</p>
          <select 
            onChange={(e) => {
              setCurrency(e.target.value as "idr" | "usd")
              setNominal("0");
            }}
            name="method" 
            className="bg-secondary-dark border border-secondary-light text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
          >
            <option value="idr">IDR</option>
            <option value="usd">USD</option>
          </select>
        </div>

        {/* quick set idr */}
        <div className="flex justify-between mb-9">
          {currency === "idr" && (
            <>
            <button
              onClick={()=>setNominal(String(10_000))} 
              className="bg-secondary-light  px-3 rounded transition hover:bg-warning active:bg-warning/80"
            >
              Rp 10.000
            </button>
            <button
              onClick={()=>setNominal(String(25_000))} 
              className="bg-secondary-light  px-3 rounded transition hover:bg-warning active:bg-warning/80"
            >
              Rp 25.000
            </button>
            <button
              onClick={()=>setNominal(String(50_000))} 
              className="bg-secondary-light  px-3 rounded transition hover:bg-warning active:bg-warning/80"
            >
              Rp 50.000
            </button>
            <button
              onClick={()=>setNominal(String(100_000))} 
              className="bg-secondary-light  px-3 rounded transition hover:bg-warning active:bg-warning/80"
            >
              Rp 100.000
            </button>
            </>
          )}

          {/* quick set usd */}
          {currency === "usd" && (
            <>
            <button
              onClick={()=>setNominal(String(10))} 
              className="bg-secondary-light  px-3 rounded transition hover:bg-warning active:bg-warning/80"
            >
              $ 10
            </button>
            <button
              onClick={()=>setNominal(String(25))} 
              className="bg-secondary-light  px-3 rounded transition hover:bg-warning active:bg-warning/80"
            >
              $ 25
            </button>
            <button
              onClick={()=>setNominal(String(50))} 
              className="bg-secondary-light  px-3 rounded transition hover:bg-warning active:bg-warning/80"
            >
              $ 50
            </button>
            <button
              onClick={()=>setNominal(String(100))} 
              className="bg-secondary-light  px-3 rounded transition hover:bg-warning active:bg-warning/80"
            >
              $ 100
            </button>
            </>
          )}

          <CurrencyInput
            placeholder="Enter Amount"
            className="text-black px-2 py-1 rounded"
            defaultValue={0}
            // decimalScale={2}
            allowNegativeValue={false}
            value={nominal}
            onValueChange={(value) => setNominal(value!)}
            intlConfig={
              currency === "idr" 
                ? {locale: "id-ID", currency:"IDR"} 
                : { locale: 'en-US', currency: 'USD' }
            }
          />
        </div>
        <div className="flex justify-between mb-2">
          <p className="text-lg font-semibold">{t("features.donation.make_donation.make_donation_modal.choose_payment")}</p>
          <select 
            onChange={e=>setPaymentMethod(e.currentTarget.value as "BNI" | "QRIS")}
            name="method" 
            className="bg-secondary-dark border border-secondary-light text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
          >
            <option value="BNI">BNI</option>
            <option value="QRIS">QRIS</option>
          </select>
        </div>
        <div className="mb-5">
          {paymentMethod === "BNI" && (
            <div className="">
              <div className="flex justify-between">
                <span className="text-gray-500">Account Number</span>
                <span>1122649143</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-500">Account Owner</span>
                <span>PT DIGITALISASI GLOBAL INKLUSIF</span>
              </div>
            </div>
          )}
          {paymentMethod === "QRIS" && (
            <img className="mx-auto" width={320} src={qrisImg as string} />
          )}
        </div>
        <div className="flex">
          <SolidButton className="basis-1/2" onClick={() => handleClickUploadFile()}>
          {t("features.donation.make_donation.make_donation_modal.upload_button")}
          </SolidButton>
        </div>
      </div>
    </DialogWithHeader>
  )
}