import { CardWithDate } from "@shared/components/card";
import { useAppSelector } from "@shared/model/hooks";
import { AnimatePresence } from "framer-motion";
import { SampleAndHackerNote } from "@entities/task/stage/sample-and-hacker-note";
import { selectCurrentStage } from "@entities/task";
import { ProcessWrapper } from "@process/task-process/components";
import { useTranslation } from "react-i18next";
import { SelectNoteAndSample } from "@features/task/select-note-and-sample";






export function SampleAndHackerNoteProcess(){
  const currentStage = useAppSelector(selectCurrentStage);
  const {t} = useTranslation();
  return (
    <CardWithDate
      date={new Date()} 
      title={t("process.task_process.sample_and_hacker_note_process.title")}
      className="h-full"
    >
      <AnimatePresence>
        {currentStage === "ANALYST_PAYMENT_CONFIRM" && (
          <SelectNoteAndSample />
          // <ProcessWrapper>
          //   <div className="p-10">
          //     <SelectNoteAndSample />
          //   </div>
          // </ProcessWrapper>
        )}
        {currentStage === "UPLOADED_HACKER_NOTE_AND_SAMPLE_FILE" && (
          <ProcessWrapper>
            <SampleAndHackerNote />
          </ProcessWrapper>
        )}
      </AnimatePresence>
    </CardWithDate>
  )
}