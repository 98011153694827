import Lottie from "lottie-react"
import loadingHourGlass from "@assets/animation/loading-hour-glass.json"
import { useTranslation } from "react-i18next"




export function SampleAndHackerNote(){

  const { t } = useTranslation();

  return (
    <div className="h-full flex flex-col px-6 pb-6">
      <p>
        Please select the analyst type first
      </p>
    </div>
  )
}