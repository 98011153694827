import { DialogWithHeader } from "@shared/components/dialog";
import { SelectVirusSection } from "../SelectVirusSection";


interface SelectVirusModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (virusName: string) => any;
}

export function SelectVirusModal(props: SelectVirusModalProps){


  return (

    <DialogWithHeader
      isOpen={props.isOpen}
      modalAccess={props.setIsOpen}
      title="Pilih Varian Virus"
      className="bg-secondary-base basis-1/3"
    >
      <SelectVirusSection onSubmit={props.onSubmit} />
    </DialogWithHeader>
  )
}