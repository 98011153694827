import { DialogWithHeader } from "@shared/components/dialog";
import { ComponentPropsWithoutRef, useState } from "react";
import { SurveyClaimGift } from "./stage/SurveyClaimGift";
import { SendQuestion, SurveyStage } from "../model/types";
import { Survey } from "./stage/Survey";
import { SurveyContent } from "@entities/survey";


interface SurveyModalProps extends Omit<ComponentPropsWithoutRef<typeof DialogWithHeader>, "title"> {
  onSendSurvey: (props: SendQuestion[]) => any;
  surveyData: SurveyContent
}



export function FreeAnalystSurveyModal(props: SurveyModalProps){

  const [stage, setStage] = useState<SurveyStage>(SurveyStage.SURVEY);

  function sendSurveyAnswer(answer: SendQuestion[]){
    // setStage(SurveyStage.GIFT);
    props.onSendSurvey(answer);
  }


  return (
    <DialogWithHeader 
      className="basis-1/2" 
      title="Kritik Berhadiah"
      isOpen={props.isOpen}
      modalAccess={props.modalAccess}
    >
      {
        {
          [SurveyStage.SURVEY]:( 
            <Survey 
              surveyContentQuestion={props?.surveyData?.questions} 
              onFinishSurvey={sendSurveyAnswer} 
              />
            ),
          // [SurveyStage.GIFT]: <SurveyClaimGift onClose={() => props.modalAccess(false)} />,
        }[stage]
      }

    </DialogWithHeader>

  )
}