
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import * as idLang from "@shared/locales/id.json";
import * as enLang from "@shared/locales/en.json";


function loadNewTranslation(locale: "en" | "id"){
  if(locale === "id") return idLang;
  return enLang;
}


function i18nWrapper(){
  return i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      supportedLngs: ['id', 'en'],
      fallbackLng: 'id',
      lng: "id",
      debug: true,
      detection : {
        order: ['htmlTag', 'cookie', 'localStorage', 'path', 'subdomain'],
        caches: ['cookie']
      },
      react : {useSuspense : false},
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      resources : {
        en : {translation : loadNewTranslation("en")},
        id : {translation : loadNewTranslation("id")}
      }
    });
}


export default i18nWrapper();