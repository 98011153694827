import { Virus } from "@entities/virus";
import { requestor } from "./requestor";
import { FixransomwareResponse } from "@shared/types/http";




export async function virusGetAllService(){
  return (await requestor.get<FixransomwareResponse<Virus[]>>("/virus", {
    params: {
      orderBy: "updatedAt",
      order: "desc",
    }
  })).data;
}

export async function virusGetAllRecentService(){
  return (await requestor.get<FixransomwareResponse<Virus[]>>("/virus", {params : {
    orderBy: "updatedAt",
    order: "desc",
    dataPerPage: 5
  }})).data;
}


export async function getVirusById(virusId: string){
  return (await requestor.get<FixransomwareResponse<Virus | null>>("/virus/" + virusId)).data
}

export async function getVirusByname(virusName: string){
  return (await requestor.get<FixransomwareResponse<Virus | null>>("/virus/name",{params: {name: virusName}})).data
}

export async function virusGetOrCreateByNameService(name: string){
  return (await requestor.post<FixransomwareResponse<Virus>>("/virus/get-or-create-by-name/", {name})).data;
}