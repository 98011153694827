import { createAsyncThunk } from "@reduxjs/toolkit";
import { answerAnalystQualificationSurvey, getMySurveyAnswer } from "@services/www.fixransomware.com/survey";
import { SendAnalystFreeSurveyPayload, SendQuestion } from "./types";






export const sendAnalystFreeSurvey = createAsyncThunk(
  "currentTask/processFreeAnalyst",
  async (props: SendAnalystFreeSurveyPayload, {getState}) => {

    const {currentTask} = getState() as {
      currentTask: {task:{id: string}}
    };

    const data = {
      taskId: currentTask?.task?.id,
      answer: props.answer
    };

    const result = await answerAnalystQualificationSurvey(props.surveyContentId, data);
    return result;
  }
 )