import { User } from "@entities/user";
import { AsyncThunk } from "@reduxjs/toolkit";

export const Phase = {
  INDEXING_WITH_AI : "Indexing with AI",
  STATIC_ANALYSIS_SAMPLE : "Static Analysis Sample",
  STATIC_ANALYSIS_PAYMENT : "Static Analysis Payment",
  DECRYPTION_PAYMENT : "Decryption Payment",
  FINISH_DECRYPTION : "Finish Decryption"
} as const;

export type AttackedDevice = {
  NAS : "NAS",
  DATABASE_WITHOUT_VIRTUALIZATION: "Database without virtualization",
  ENDPOINT_DEVICE : "Endpoint Device",
  CLOUD_SYSTEM : "Cloud System",
  SERVER_VIRTUALIZATION : "Server Virtualization",
  OTHER : string,
}

export type TaskExtraType = {
  attackedDevice : {
    deviceType : keyof AttackedDevice,
    value: AttackedDevice[keyof AttackedDevice]
  },
  dataEstimation : number // in gb
  affectedDevice : number
  topology : string // id where file stored
}


export interface analystPreviewDataType {
  analystPreviewFile?: string,
  analystPreviewPaymentNominal?: number,
  analystPreviewPaymentProof?: string,
  analystPreviewLink: string,
  analystPreviewPaymentDiscount?: number,
  analystPreviewPaymentMethod?: "QRIS" | "BNI",
  analystPreviewPaymentCurrency?: "IDR" | "USD",
  analystPreviewPaymentStatus?: "Success" | "Pending" | "Init"
}  

export const TaskAnalystStatus = {
  INIT: "Init",
  PENDING: "Pending",
  SUCCESS: "Success",
  FAILED: "Failed"
} as const;


export const TaskAnalystType = {
  FREE: "FREE",
  MANUAL:  "MANUAL",
  FAST:  "FAST"
} as const;

export interface Task {
  id?                      : string
  userId                  ?: (string | User)
  sampleFile              ?: string[]
  hackerNote              ?: string[]
  status                  ?: typeof Phase[keyof typeof Phase]
  analystPaymentMethod    ?: string        // # it should be "bri" or "qris"
  analystPaymentDeadline  ?: string // Date
  currency                ?: string
  analystType             ?: string
  analystNominal          ?: string
  analystDiscount         ?: string
  analystProof            ?: string        // # where file path placed
  analystStatus           ?: string
  downloadAnalysis        ?: string
  decryptProof            ?: string        // # same like above, where file path placed
  decryptDiscount         ?: string
  decryptPaymentMethod    ?: string        // # "bri" or "qris"
  decryptPaymentDeadline  ?: string //Date
  decryptNominal          ?: string
  decryptCurrency         ?: string        // # "idr" or "usd"
  decryptPaymentStatus    ?: string        // # idk, should find documentation for this type
  estimatedDecrypt        ?: string
  estimatedDecryptFinish  ?: string
  decryptFile             ?: string
  percentage              ?: string        // # progress percentage, updated when task change progress
  virusVarian             ?: string
  virus                   ?: string
  fileIndexing            ?: string
  extra                   ?: TaskExtraType
  analystPreviewData      ?: analystPreviewDataType
  // see : https://stackoverflow.com/questions/61704805/getting-an-error-a-non-serializable-value-was-detected-in-the-state-when-using
  // redux doesn't accept non serializable object as value, that included date object, so i have to make sure this field are string
  createdAt               ?: string // Date
  updatedAt               ?: string // Date

}


export type GenericAsyncThunk = AsyncThunk<Task, string, any>;
export type PendingAction = ReturnType<GenericAsyncThunk["pending"]>;
export type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>;
export type FulfilledAction = ReturnType<GenericAsyncThunk["fulfilled"]>;


export type AnalystType = "FREE" | "MANUAL" | "FAST";

export type CurrentTaskSliceState = {
  loading: boolean;
  error: any;
  task: Task;
  checkUp: boolean;
  activeAnalystType?: AnalystType;
}


export {}