import { cn } from "@shared/utils/tailwind-merge";
import { indexOf } from "lodash-es";
import { Input } from "postcss";
import { ChangeEvent, ReactElement, ReactNode, useId, useMemo, useState } from "react";




interface RadioSwitchGroupProps{
  name: string;
  children: ReactElement<RadioSwitchInputProps>[];
  onChange?: (value: string) => void
}

interface RadioSwitchInputProps{
  value: string;
  children: ReactNode;
}

interface ButtonRadioSwitchProps {
  value: string;
  children: ReactNode;
  activeValue: string;
  index: number;
  selectedKey?: number;
  onChange: (key: number, value: string) => void
}

export function RadioSwitchInput(props: RadioSwitchInputProps){
  return (
    props.children
  )
}

function ButtonRadioSwitch(props: ButtonRadioSwitchProps){
  const buttonRadioId = useId()
  const isSelected = useMemo(
    () => props.index === props.selectedKey, 
    [props.index, props.selectedKey]
  )

  function handleOnChange(event: ChangeEvent<HTMLInputElement>){
    props.onChange(props.index, props.value);
  }

  return (
    <div  
      style={{
        // boxShadow: "0 1px 3px 0 rgb(20 0 0 / 0.1), 0 1px 2px -1px rgb(20 0 0 / 0.1);"
        // boxShadow: "1px 3px 1px #9E9E9E"
        boxShadow: "0px 0px 7px #0ea5e9"
      }}
      className={cn(
        "p-1 rounded select-none transition", 
        isSelected && "bg-white text-secondary-dark -translate-x-1 -translate-y-1"
      )}
    >
      <input 
        className="hidden"
        onChange={handleOnChange}
        type="radio" 
        name={props.value} 
        id={buttonRadioId} 
        checked={isSelected}  
      />
      <label htmlFor={buttonRadioId}>
        {props.children}
      </label>
    </div>
  )
}

export function RadioSwitchGroup(props: RadioSwitchGroupProps){

  const [selectedRadio, setSelectedRadio] = useState<number>();
  const [selectedValue, setSelectedValue] = useState<string>("");

  function onChangeRadio(key: number, value: string){
    setSelectedRadio(key);
    setSelectedValue(value);

    if(props.onChange) props.onChange(value);
  }

  return (
    <div className="flex gap-2 items-center">
      { 
      props.children.map((rad, index) => (
          <ButtonRadioSwitch 
            onChange={onChangeRadio}
            activeValue={selectedValue}
            value={rad.props.value}
            key={index}
            index={index}
            selectedKey={selectedRadio}
          >
            {rad}
          </ButtonRadioSwitch>
        ))
      }
    </div>
  )

}



export const RadioSwitch = {
  Group: RadioSwitchGroup,
  Input: RadioSwitchInput
}