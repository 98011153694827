import { SelectAnalystType } from "@features/task/select-analyst-type";
import Elipse from "@assets/img/elipse.png";




export function SelectAnalystTypeProcess(){ 

  return (
    <div className=" gap-9 xl:gap-28 h-full relative ">
    <div className="w-[80%] h-full flex flex-col justify-center mx-auto relative z-10"> 
      <SelectAnalystType />
    </div>
    <div className="absolute top-0 bottom-0 left-0 right-0 overflow-hidden z-0">
      <img className="absolute top-0 left-0 -translate-x-1/2 -translate-y-1/2" src={Elipse as string} />
      <img className="absolute bottom-0 right-0 translate-x-1/2 translate-y-1/2" src={Elipse as string} />
    </div>
  </div>
  )
}