import { Virus } from "@entities/virus";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProcessVirusAndIndexArgs } from "./type";
import { taskCreateService } from "@services/www.fixransomware.com";
import { User } from "@entities/user";
import { AnalystType } from "@entities/task";





/**
 * create a new task
 */
export const processVirusAndIndex = createAsyncThunk(
  "currentTask/processVirusAndIndex",
  async (payload: ProcessVirusAndIndexArgs, {getState}) => {
    const {currentVirus, currentUser} = getState() as {
      currentVirus: {virus : Virus},
      currentUser :{currentUser : User},
      // currentTask: {activeAnalystType?: AnalystType}
    };

    // if(!currentTask.activeAnalystType) throw new Error("activeAnalystType not initialized");

    const formIndexingData = new FormData();

    formIndexingData.append("userId", currentUser.currentUser.id);
    formIndexingData.append("virusVarian", currentVirus.virus.virusName);
    formIndexingData.append("virus", currentVirus.virus.id);
    formIndexingData.append("file", payload.indexingFile!);
    // formIndexingData.append("taskAnalystType",currentTask.activeAnalystType);
   
    const result = await taskCreateService(formIndexingData);
    
    // const result = await taskCreateService({
    //   userId: currentUser.currentUser.id,
    //   virusVarian: currentVirus.virus.virusName,
    //   file:payload.indexingFile
    // });

    if(!result.data) throw new Error("fail to create new task");

    return result.data;
  }
)