import { DialogWithHeader } from "@shared/components/dialog";
import { ComponentPropsWithoutRef } from "react";


interface PaymentHaveNotInitializedProps extends Omit<ComponentPropsWithoutRef<typeof DialogWithHeader>, "title"> {
}


export function PaymentHaveNotInitialized(props: PaymentHaveNotInitializedProps) {

  return (
    <>
    <DialogWithHeader
      className="basis-1/3"
      title="Pembayaran Analisis"
      {...props}
    >
      <div className="p-20">
        <p>Pembayaran analisis belum diinisialisasi oleh admin, mohon tunggu sebentar</p>
      </div>
    </DialogWithHeader>
  </>
  )
}