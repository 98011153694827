import { AnalystType, AnalystTypeConst, setActiveAnalystType } from "@entities/task";
import { CardDropdown } from "@shared/components/card";
import { CardBase } from "@shared/components/card/base/CardBase";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { useAppDispatch } from "@shared/model/hooks";
import { OfferList } from "./OfferList";
import FolderOpen from "@assets/icon/folder-open.svg?react";
import Acute from "@assets/icon/acute.svg?react";
import Medal from "@assets/ilustration/medal.png"
import { selectAnalystTypeAction } from "../model/action";
import { useTranslation } from "react-i18next";
import { cn } from "@shared/utils/tailwind-merge";
import { DefaultDialog, DialogWithHeader } from "@shared/components/dialog";
import { useState } from "react";





interface SelectAnalystTypeProp{
  onSelectAnalystType?: () => any;
}




export function SelectAnalystType(props: SelectAnalystTypeProp){

  const {t} = useTranslation();

  const dispatch = useAppDispatch();

  const [isFreeModalOpen, setIsFreeModalOpen] = useState(false);

  function selectAnalystType(type: AnalystType){
    console.log("it should dispatch the analys type...")
    dispatch(selectAnalystTypeAction({taskAnalystType: type}));
    props.onSelectAnalystType ? props.onSelectAnalystType() : "";
  }

  return (
    <>
    <div className="mb-16 max-w-2xl text-center mx-auto">
      <h1 className=" font-bold text-3xl mb-2">{t("features.task.select_analyst_type.title")}</h1>
      <p>{t("features.task.select_analyst_type.subtitle")}</p>
    </div>
    <div className="flex gap-3">


      
      {/* dropdown card 1 (manual) */}
      <CardDropdown 
        isInitialCollapse={false}
        className="basis-1/3  shrink-0 p-10 rounded-xl bg-primary-light text-white shadow-lg" 
        title={t("features.task.select_analyst_type.cards.manual.title")}
        list={[
          (
            <OfferList key={1} icon={<FolderOpen />}>
            {t("features.task.select_analyst_type.cards.manual.list_1")}
            </OfferList>
          ),
          (
          <OfferList key={2} icon={<Acute />}>
            {t("features.task.select_analyst_type.cards.manual.list_2")}
          </OfferList>
          ),
        ]}
        buttonSlot={
          <SolidButton 
            className="text-white rounded-lg w-fit" 
            onClick={() => selectAnalystType(AnalystTypeConst.MANUAL)}
          >
            {t("features.task.select_analyst_type.cards.manual.choose")}
          </SolidButton>
        }
      >
        <p className="mb-3">
        {t("features.task.select_analyst_type.cards.manual.description")}
        </p>
      </CardDropdown>
        
      {/* dropdown card 2 (FAST) */}
      <CardDropdown 
        isInitialCollapse={false}
        className={cn(
          "bg-[linear-gradient(45deg,transparent_25%,rgba(225,225,225,.2)_50%,transparent_75%,transparent_100%)]  bg-[length:250%_250%,100%_100%] bg-[position:-100%_0,0_0] bg-no-repeat transition-[background-position] hover:bg-[position:200%_0,0_0] hover:duration-[1500ms]",
          " basis-1/3 shrink-0 p-10 rounded-xl -translate-y-10 static hover:opacity-100 bg-warning-dark text-white shadow-lg")} 
        title={t("features.task.select_analyst_type.cards.fast.title")}
        list={[
          (
            <OfferList key={1} icon={<FolderOpen />}>
              {t("features.task.select_analyst_type.cards.fast.list_1")}
            </OfferList>
          ),
          (
          <OfferList key={2} icon={<Acute />}>
           {t("features.task.select_analyst_type.cards.fast.list_2")}
          </OfferList>
          ),
        ]}
        buttonSlot={
        <SolidButton 
          variant="default"
          className="text-black bg-gray-100 hover:bg-white rounded-lg w-fit" 
          onClick={() => selectAnalystType(AnalystTypeConst.FAST)}
        >
          {t("features.task.select_analyst_type.cards.fast.choose")}
        </SolidButton>
        }
      >
        <p className="mb-3">
          <img src={Medal as string} className="absolute -right-5 -top-5 w-12" />
          {t("features.task.select_analyst_type.cards.fast.description")}
        </p>
      </CardDropdown>

      
      {/* dropdown card 3 (FREE) */}
      <CardDropdown 
        isInitialCollapse={false}
        className="basis-1/3 p-10 rounded-xl shadow-lg" 
        title={t("features.task.select_analyst_type.cards.free.title")}
        list={[
          (
          <OfferList key={1} icon={<FolderOpen />}>
            {t("features.task.select_analyst_type.cards.free.list_1")}
          </OfferList>
          ),
          (
          <OfferList key={2} icon={<Acute />}>
            {t("features.task.select_analyst_type.cards.free.list_2")}
          </OfferList>
          ),
        ]}
        buttonSlot={
          <SolidButton 
            className="text-white rounded-lg w-fit" 
            onClick={() => setIsFreeModalOpen(true)}
          >
            {t("features.task.select_analyst_type.cards.free.choose")}
          </SolidButton>
        }
      >
        <p className="mb-3">
        {t("features.task.select_analyst_type.cards.free.description")}
        </p>
      </CardDropdown>

    </div>
    <DialogWithHeader
      modalAccess={setIsFreeModalOpen}
      // title=""
      isOpen={isFreeModalOpen}
      className="max-w-xl"
    >
      <div className="pb-5">
        <h5 className="text-warning px-28 text-center mb-4">
          {t("features.task.select_analyst_type.cards.free.confirm_title")}
        </h5>
        <p className="mb-6 px-28 text-lg text-center font-semibold">
          {t("features.task.select_analyst_type.cards.free.confirm_description")}
        </p>

        <SolidButton 
          className="text-white mx-auto block rounded-lg w-fit" 
          onClick={() => selectAnalystType(AnalystTypeConst.FREE)}
        >
          {t("features.task.select_analyst_type.cards.free.confirm_next")}
        </SolidButton>
      </div>
    </DialogWithHeader>
    </>
  )
}