import { selectUser } from "@entities/user";
import { useAppDispatch, useAppSelector } from "@shared/model/hooks";
import { useEffect } from "react";
import { getCurrentTask } from ".";
import { toast } from "react-toastify";
import { selectTaskCheckup, setOnCheckUpTask } from "@entities/task";
import { getCurrentVirus } from "@features/virus/setup-current-virus";






/**
 * setup task and it's virus data
 * @todo :  persist data per refresh using session storage
 * python -m http.server
 */
export function useSetupCurrentTask(){
  const user = useAppSelector(selectUser);
  const taskCheckUpStatus = useAppSelector(selectTaskCheckup);
  const dispatch = useAppDispatch();

  useEffect(()=>{
    hadleSetTask();
  },[user]);

  async function hadleSetTask(){
    try {
      if(!user) return;
      if(taskCheckUpStatus) return;
      
      /* const result = */ await dispatch(getCurrentTask()).unwrap();

      await dispatch(setOnCheckUpTask()); // don't refecth when this flag already true
    } catch (error) {
      console.log("error saat nganu task")
      toast.error((error as unknown as Error).message);
    }
  }
}