import { SolidButton } from "@shared/components/form/button/SolidButton";
import { useEffect, useState } from "react";
import { SurveyModal } from "./SurveyModal";
import { answerSurvey, getMySurveyAnswer, getSurveyByType, getSurveyContent } from "@services/www.fixransomware.com/survey";
import { useAppDispatch, useAppSelector } from "@shared/model/hooks";
import { checkIfSurveyAlreadyAnswered } from "../model/action";
import { selectSurveyState, setSurveyIsAlreadyAnsewer, SurveyContent } from "@entities/survey";
import { SendQuestion } from "../model/types";
import { selectTask } from "@entities/task";
import GiftIcon from "@assets/icon/gift.svg?react"
import clsx from "clsx";
import i18next from "i18next";
import { toast } from "react-toastify";


const SurveyTypeByLanguage = {
  id: "gift",
  en: "gift-en"
} as const;




export function SurveyButton(){

  const [isModalOpen, setIsOpenModalOpen] = useState(false);
  const [surveyData, setSurveyData] = useState<SurveyContent | null>(null);

  const dispatch = useAppDispatch();
  const survey = useAppSelector(selectSurveyState);
  const currentTask = useAppSelector(selectTask);

  useEffect(() =>{
    isSurveyAlreadyAnswered();
    showSurveyService();
  },[]);


  async function isSurveyAlreadyAnswered() {
    await dispatch(checkIfSurveyAlreadyAnswered());
  }


  async function sendAnswer(props: SendQuestion[]){

    if(!surveyData?.id) {
      toast.error("survey data not defined");
      return;
    }

    console.log("send survey answer");
    console.log(props);
    const data = {
      taskId: currentTask.id!,
      answer: props
    }

    console.log(data);

    const result = await answerSurvey(surveyData.id, data);
    // console.log(result);
    dispatch(setSurveyIsAlreadyAnsewer(true));
  }

  async function showSurveyService(){
    if(i18next.resolvedLanguage == "id"){
      const surveyContent = await getSurveyByType(SurveyTypeByLanguage.id);
      console.log(surveyContent);
      setSurveyData(surveyContent);
      return;
    }
    const surveyContent = await getSurveyByType(SurveyTypeByLanguage.en);
    setSurveyData(surveyContent);
    return;
    
  }

  if(survey.loading) return (
    <SolidButton className="py-2" onClick={() => {}}>
      Loading.....
    </SolidButton>
  )


  if(!surveyData) return (    
    ""
    // <SolidButton disabled className="py-2" onClick={() => {}}>
    //   survey tidak ditemukan
    // </SolidButton>
  )

  return (
    <>

      {/* show when user already take the survey */}
      <SolidButton 
        disabled 
        className={clsx(
          "py-2", 
          (!survey.isAlreadyAnswered) ? "hidden" : "")
        } 
        onClick={() => {}}
      >
        Survey sudah dikerjakan
      </SolidButton>

      {/* show when user haven't take the survey. will open the survey modal when you click it */}
      <SolidButton 
        className={clsx(
          "py-2 flex justify-between items-center",
          (survey.isAlreadyAnswered) ? "hidden" : ""
         )}
        onClick={() => setIsOpenModalOpen(!isModalOpen)}
      >
        <GiftIcon className="w-5" />
        Kritik Berhadiah
      </SolidButton>

      {/* the survey modal */}
      <SurveyModal 
        surveyData={surveyData}
        onSendSurvey={sendAnswer}  
        isOpen={isModalOpen} 
        modalAccess={setIsOpenModalOpen}  
      />
    </>
  )
}