import { useMemo, useState } from "react";
import { FreeAnalystSurveyTimeLine, TimeLineData } from "../SurveyTimeLine";
import GiftIcon from "@assets/icon/gift.svg?react"
import { Question, SendQuestion } from "@features/survey/take-survey/model/types";
import { SurveyQuestion } from "./SurveyQuestion";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { useTranslation } from "react-i18next";


function generateTimeDataContent(description: string): TimeLineData{
  return {
    PointElement: "",
    description: description
  }
}

interface SurveyProps {
  surveyContentQuestion: Question[];
  onFinishSurvey: (asweredQuestion: SendQuestion[]) => void;
}

export function Survey(props: SurveyProps){

  const [questionIndex, setQuestionIndex] = useState(0);
  const [answerContainer, setAnswerContainer] = useState<SendQuestion[]>([]);

  const {t, i18n} = useTranslation();

  const timeLineData = useMemo(() => {
    let timelineTemp: TimeLineData[] = [];
    props?.surveyContentQuestion?.forEach((val, index) => {
      timelineTemp.push(generateTimeDataContent( t("features.task.take_survey_for_analyst_term.question") + " - " + Number(index + 1)));
    });

    timelineTemp.push({
      PointElement: <GiftIcon className="w-5" />,
      description: "Finish"
    })
    
    return timelineTemp;
  }, [props?.surveyContentQuestion?.length])

  function onUpdateAnswer(answerPayload: SendQuestion){
    const newArray = [...answerContainer];

    const foundedIndex = newArray.findIndex(arr => arr.questionId === answerPayload.questionId);

    if(foundedIndex < 0) {
      newArray.push(answerPayload);
      setAnswerContainer(newArray);
      return;
    };

    newArray[foundedIndex] = answerPayload;

    setAnswerContainer(newArray);
  }



  return (
    <div className="p-3">

    <FreeAnalystSurveyTimeLine activeTimeLine={questionIndex} timeLineData={timeLineData} />

    <div className="p-5 border border-secondary-light rounded-lg mb-2">
      <div className="flex justify-between mb-8">
        {i18n.resolvedLanguage === "id"
          ? (<small>{`Pertanyaan ${questionIndex + 1} dari ${props.surveyContentQuestion.length}`}</small>)
          : (<small>{`Question ${questionIndex + 1} from ${props.surveyContentQuestion.length}`}</small>)
        }
        <p className="bg-primary text-white px-5 py-1 rounded "> 1 Point</p>
      </div>

      <SurveyQuestion
        question={props.surveyContentQuestion[questionIndex]} 
        answerQuestion={answerContainer[questionIndex]}
        onSelectQuestion={onUpdateAnswer}  
        key={props.surveyContentQuestion[questionIndex].id}
      />

    </div>
    <div className="flex justify-between">
      <SolidButton
        variant="base" 
        onClick={questionIndex > 0 ?() => setQuestionIndex(questionIndex - 1) : ()=>{} }
        disabled={questionIndex <= 0}
      > 
        {t("features.task.take_survey_for_analyst_term.prev")} 
      </SolidButton>

      {!(questionIndex >= props.surveyContentQuestion.length -1 )
      ?(
        <SolidButton
          onClick={questionIndex < props.surveyContentQuestion.length - 1 ? () => setQuestionIndex(questionIndex + 1) : ()=>{} }
          disabled={questionIndex >= props.surveyContentQuestion.length - 1}
        > 
        {t("features.task.take_survey_for_analyst_term.next")}
        </SolidButton>
      ) :(
        <SolidButton
          onClick={() => props.onFinishSurvey(answerContainer)}
        >
          {t("features.task.take_survey_for_analyst_term.submit")}
        </SolidButton>
      )}
    </div>
  </div> 
  )
}