import { setOffCheckUpTask, setOnCheckUpTask } from "@entities/task";
import { selectUser } from "@entities/user";
import { changeCurrentTask } from "@features/task/change-current";
import { TaskProcessProgress } from "@process/task-process";
import { useAppDispatch, useAppSelector } from "@shared/model/hooks";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { toast } from "react-toastify";






export function TaskPage(){
  const { taskId } = useParams();
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(()=>{
    setUpCurrentTaskState();
  },[user, taskId]);

  async function setUpCurrentTaskState(){

    // don't process when user hasn't been set
    if(!user) return;

    try {
      await dispatch(changeCurrentTask(taskId!)).unwrap();
      dispatch(setOffCheckUpTask()); // refresh task in home;
      setIsLoading(false);
    } catch (error) {
      toast.error("gagal mengatur task : " + taskId);
    }
  }

  // useEffect(()=>{
  //   if(!taskId) return;
  //   console.log("halloooooo")
  //   setUpCurrentTaskState();
  // },[taskId])

  if(isLoading) return (
    <p className="text-center">Loading.....</p>
  )
  return (
    <TaskProcessProgress/>
  )
}