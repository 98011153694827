import { OutlineButton, TextInput } from "@shared/components/form";
import { useRegisterHook } from "..";
import ReactSelect from "react-select";
import { INSTITUIION_TYPE } from "../data/InstitutionType";
import { countriesNumber } from "../data";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo } from "react";
import indonesianCity from "./../data/indonesia-city.json";
import i18next from 'i18next'
import { PasswordInput } from "@shared/components/form/input/PasswordInput";
import { parseInstitutionListToSelectOption } from "../model/utils";


export interface RegisterFormProps {
  referralCode?: string;
}


export function RegisterForm(props: RegisterFormProps){

  

  const provinceData = useMemo(() => {
    const province = indonesianCity.map(item => item.provinsi);
    return province;
  }, [indonesianCity]);

  const cityData = useMemo(()=>{
    const cities = indonesianCity.map(item => item.kota);
    const flattenCities = cities.flat(1);
    return flattenCities;
  },[indonesianCity]);

  const { 
    handleFormChange, 
    handleOnSubmit, 
    registerForm, 
    handleDropdownInstitutionChange, 
    handleDropdownPhoneCodeChange,
    setReferralCode,
    handleDropdownCityChange,
    handleDropdownProvinceChange,
    institutionList
  } = useRegisterHook();
  const { t, i18n } = useTranslation();

  useEffect(()=>{
    setReferralCode(props.referralCode);

  },[]);


  return (
    <>
    <form className="" onSubmit={handleOnSubmit}>
      <div className="flex w-full mb-2 gap-5">

        {/* fullname input */}
        <TextInput 
          value={registerForm.fullname} 
          onChange={handleFormChange("fullname")}
          name="fullname" 
          placeholder={t("features.auth.register.form.fullname")}
          className="basis-1/2" 
        />

        {/* email input */}
        <TextInput 
          value={registerForm.email}
          onChange={handleFormChange("email")}
          name="email" 
          placeholder={t("features.auth.register.form.email")}
          className="basis-1/2" 
        />
      </div>
      <div className="flex items-stretch w-full mb-2 gap-5">

        {/* coutry code input */}
        <ReactSelect
          
          // -- state management
          name="phoneCountryCode"
          defaultValue={{value: "ID", label: "+62"}}
          options={countriesNumber.map(item => ({label: item.code, value: item.countryCode}))}
          onChange={(e) => handleDropdownPhoneCodeChange(e?.value)}
          // value={""}

          // -- style scope
          styles={{
            control: base => ({
              ...base,
              display: "flex",
              height: "100%",
              borderBottom: "4px solid #FFA50D"
            })
          }}
          className="basis-2/5"
          isSearchable={true}
          formatOptionLabel={phone => (
            <div className="h-full text-black">
              <span>( {phone.value} ) {phone.label}</span>
            </div>
          )}
        />

        {/* phone number input */}
        <TextInput 
          value={registerForm.phoneNumber}
          onChange={handleFormChange("phoneNumber")}
          name="phoneNumber" 
          type="number"
          placeholder={t("features.auth.register.form.phone_number")}
          inputClassName="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          className="w-full" 
        />
      </div>
      <div className="flex items-stretch w-full mb-2 gap-5">

        {/* institution type select */}
        <ReactSelect
        
          // -- state management
          name="institutionType"
          // defaultValue={{value: "PT", label: "PT"}}
          options={parseInstitutionListToSelectOption(institutionList)}
          onChange={(e) => handleDropdownInstitutionChange(e?.value)}
          // value={""}

          // -- style scope
          styles={{
            control: base => ({
              ...base,
              display: "flex",
              height: "100%",
              borderBottom: "4px solid #FFA50D"
            })
          }}
          className="basis-2/5"
          isSearchable={false}
          formatOptionLabel={institutionType => (
            <div className="h-full text-black">
              <span>{institutionType.label}</span>
            </div>
          )}
        />

        {/* institution name */}
        <TextInput 
          value={registerForm.institution}
          onChange={handleFormChange("institution")}
          name="intitution" 
          placeholder={t("features.auth.register.form.institution_name")}
          className="w-full"  
        />
      </div>

      {/* showprovince and city when country code in indonesia */}
      {(registerForm.phoneCountryCode === "ID" && i18n.resolvedLanguage === "id" ) && (
        <>
        <div className="flex items-stretch w-full mb-2 gap-5">
          <ReactSelect
            name="province"
            defaultValue={{value: "None", label: "Provinsi"}}
            options={provinceData.map(item => ({label: item, value: item}))}
            onChange={(e) => handleDropdownProvinceChange(e?.value)}
            // value={""}

            // -- style scope
            styles={{
              control: base => ({
                ...base,
                display: "flex",
                height: "100%",
                borderBottom: "4px solid #FFA50D"
              })
            }}
            className="basis-1/2"
            isSearchable={true}
            formatOptionLabel={phone => (
              <div className="h-full text-black">
                <span>{phone.label}</span>
              </div>
            )}
          />
          <ReactSelect
            
            // -- state management
            name="city"
            defaultValue={{value: "None", label: "Kota"}}
            options={cityData.map(item => ({label: item, value: item}))}
            onChange={(e) => handleDropdownCityChange(e?.value)}
            // value={""}

            // -- style scope
            styles={{
              control: base => ({
                ...base,
                display: "flex",
                height: "100%",
                borderBottom: "4px solid #FFA50D"
              })
            }}
            className="basis-1/2"
            isSearchable={true}
            formatOptionLabel={phone => (
              <div className="h-full text-black">
                <span>{phone.label}</span>
              </div>
            )}
          />
        </div>
        </>
      )}
    
      <TextInput 
        value={registerForm.address}
        onChange={handleFormChange("address")}
        name="address" 
        placeholder={t("features.auth.register.form.address")}
        className="mb-2" 
      />
      <PasswordInput
        value={registerForm.password}
        onChange={handleFormChange("password")}
        name="password" 
        placeholder={t("features.auth.register.form.password")}
        className="mb-2" 
      />
      <div className="flex flex-row-reverse">
        <OutlineButton>{t("features.auth.register.form.submit_button")}</OutlineButton>
      </div>
    </form>
    </>
  )
}