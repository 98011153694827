import { useState } from "react";
import { ScanButton } from "../scan-button/ScanButton";
import { SelectVirusModal } from "../modal-select-virus/SelectVirusModal";
import { useAppDispatch } from "@shared/model/hooks";
import { useNavigate } from "react-router-dom";
import { updateName } from "@entities/virus";



interface UploadIndexFileProps {
  isDisable?: boolean;
  onSelectVirus?: (virusName: string) => any;
}


export function UploadIndexFile(props: UploadIndexFileProps){

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function checkVirusExistence (virusName: string){


    // set nama virus di global state
    dispatch(updateName(virusName));
    if(props.onSelectVirus) props.onSelectVirus(virusName);
    // navigate("/scan");
  }

  const [isOpenFileIndexingModal, setIsOpenFileIndexingModal] = useState<boolean>(false);
  return (
    <>
      <ScanButton 
        isDisable={props.isDisable}
        onClick={() => setIsOpenFileIndexingModal(true)} />
      <SelectVirusModal 
        onSubmit={checkVirusExistence} 
        isOpen={isOpenFileIndexingModal} 
        setIsOpen={setIsOpenFileIndexingModal} 
      />
    </>
  )
}