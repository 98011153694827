import { Task } from "@entities/task/model";
import { DialogWithHeader } from "@shared/components/dialog";
import { ComponentPropsWithoutRef, ReactElement, useState } from "react";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { OutlineButton } from "@shared/components/form";
import { PaymentAnalystTransferData } from "../model";
import { getAnalystInvoiceService } from "@services/www.fixransomware.com";
import { toast } from "react-toastify";
import { FormatIDR, calculateGrandTotal } from "@shared/utils";
import { useTranslation } from "react-i18next";
import { CommonPayment } from "@shared/components/payment";

interface PaymentAnalystModalProps extends Omit<ComponentPropsWithoutRef<typeof DialogWithHeader>, "title"> {
  task: Task;
  children?: ReactElement;
  getFileModalOpen: () => any;
  setTransferData: (arg: PaymentAnalystTransferData) => any;
}



export function PaymentAnalystModal({task, getFileModalOpen, /* children,  */setTransferData, ...props}: PaymentAnalystModalProps){
  const [paymentMethod, setPaymentMethod] = useState<"BNI" | "QRIS" | "PAYPAL">("BNI");
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  function hadleClickUploadFile(){
    setTransferData({paymentMethod});
    getFileModalOpen();
  }

  async function hadleDownloadInvoice(){
    setIsLoading(true);
    try {
      // create file link in browser's memory
      const blob = await getAnalystInvoiceService({ id: task.id ?? ""});
      const href = URL.createObjectURL(blob);
      console.log(href);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'Invoice Analisis Statis Fixransomware - Tanpa PPN.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);

    } catch (error) {
      console.log(error);
      toast.error(t("features.task.analyst_payment.payment_analys_modal.error_when_download"));
      // toast.error("ada masalah dalam mengunduh data");

    }finally{
      setIsLoading(false);
    }
  }

  return (
  <>
    <DialogWithHeader
      className="basis-1/3"
      title="Pembayaran Analisis"
      {...props}
    >
      <CommonPayment 
        amount={task.analystNominal!}
        deadline={new Date(task.analystPaymentDeadline!)}
        discount={task.analystDiscount!}
        currency={task.currency as "IDR" | "USD"}
        invoice={task.id!}
        paymentMethod={paymentMethod}
        status={task.status!}
        onPaymentMethodChange={setPaymentMethod}
        total={calculateGrandTotal(task.analystNominal!, task.analystDiscount!)}
        buttonSlot={
          <>
            <SolidButton onClick={hadleClickUploadFile} className="basis-1/2">
              {t("common.common_payment.upload_proof")}
              {/* Upload Proof */}
            </SolidButton>
            <OutlineButton 
              isLoading={isLoading}
              onClick={hadleDownloadInvoice} 
              className="basis-1/2"
            >
              {t("common.common_payment.download_invoice")}
              {/* Download Invoice */}
            </OutlineButton>
          </>
        }
      />
    </DialogWithHeader>
  </>
  )
}