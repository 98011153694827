import { cn } from "@shared/utils/tailwind-merge";
import { ComponentPropsWithoutRef, ReactElement, ReactNode, useState } from "react";
import ChevronDown from "@assets/icon/chevron-down.svg?react";

interface CardDropdownProps extends ComponentPropsWithoutRef<"div">{
  title?: string;
  list?: ReactNode[];
  buttonSlot?: ReactNode;
  isInitialCollapse?: boolean
}

export function CardDropdown({className,title,list, isInitialCollapse = true,  children,...props} : CardDropdownProps){

  const [isCollapse, setIsCollapse] = useState(isInitialCollapse);

  return (
    <div 
      className={cn(
        "rounded bg-white shadow flex flex-col text-black p-5 hover:opacity-85 min-h-0",
        className
      )}
    >
      <div className="flex justify-between border-b border-b-gray-300 border-opacity-65 pb-2 mb-2">
        <p className=" text-2xl font-bold ">{(title)?.toUpperCase()}</p>
        <button onClick={() => setIsCollapse(!isCollapse)}>
          <ChevronDown className={cn("w-5 ", {"rotate-180": !isCollapse})} />
        </button>
      </div>
      <section className="h-full flex flex-col">
        <div className={cn("h-full overflow-hidden transition-all ", isCollapse ? "max-h-0" : "max-h-48")}>
          {children}
        </div>
        {list}
        <div className="mb-4" />
        {props.buttonSlot}
      </section>
    </div>
  )
}