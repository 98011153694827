import { Task } from "@entities/task";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";




interface DecryptResultProps {
  bottomButtonSlot?: ReactNode;
  task: Task
}

export function DecryptResult(props: DecryptResultProps){

  const { t } = useTranslation();

  return(
    <div className="h-full flex flex-col gap-10">
      <div className="h-full">
        <h2 className="text-lg font-semibold">
          {t("entities.task.stage.decrypt.decrypt_result.congratulation")}
        </h2>
        <p>
          {t("entities.task.stage.decrypt.decrypt_result.message")}
        </p>
      </div>
      <div className="flex gap-2">
        <a href={props.task.decryptFile} target="_blank" rel="noopener noreferrer">
          <SolidButton variant="warning">
            Download File
          </SolidButton>
        </a>
        {props.bottomButtonSlot}
      </div>
    </div>
  )
}