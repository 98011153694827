import { Institution } from "@entities/institution";






export function parseInstitutionListToSelectOption(institutionList: Institution[]){
  // console.log("institution list: ")
  // console.log(institutionList);
  return institutionList.map(ins => ({label: ins.institutionName, value: ins.institutionCode}));
}