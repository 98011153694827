




export enum TaskProcessStatus {
  EMPTY = 1,
  INDEXING_AND_VIRUS_UPLOAD,
  HACKER_NOTE_AND_SAMPLE_UPLOAD,
  ANALYST,
  DECRYPT,
}