import HomeIcon from "@shared/assets/icon/home.svg?react";
import clsx from "clsx";
import { Link } from "react-router-dom";





export function NotFound(){



  return (
    <div className="flex flex-col  h-screen max-h-screen">
      <div className="bg-secondary-base max-h-full overflow-hidden h-full flex flex-col">
        <main className="text-white flex flex-col justify-center h-full overflow-x-hidden">
          <section className="">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
              <div className="mx-auto max-w-screen-sm text-center">
                <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary ">404</h1>
                <p className="mb-4 text-3xl tracking-tight font-bold  md:text-4xl ">Something's missing.</p>
                <p className="mb-4 text-lg font-light text-gray-500 ">Homon maaf, halaman yang anda minta tidak tersedia. </p>
                <Link
                  title="Pergi Ke Home" 
                  to={"/home"} 
                  className={clsx(
                    "inline-flex items-center gap-2 px-5 py-1.5 text-gray-200 rounded-full bg-secondary-dark border",
                    "border-secondary-dark hover:border-blue-600 hover:bg-gray-900 active:bg-gray-800"
                  )}
                >
                  <HomeIcon className="" width={20} height={20} /> 
                  <p className="block">
                    Home
                  </p>
                </Link>
              </div>   
            </div>
          </section>
        </main>
      </div>
    </div>


  )
}