import { useAppDispatch } from "@shared/model/hooks";
import { ChangeEvent, FormEvent, useState } from "react";
import { ForgotPasswordPayload, forgotPassword } from ".";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";



export function useForgotPasswordHook(){
  const dispatch = useAppDispatch();
  const {t} = useTranslation()
  const [forgotPasswordForm, setForgotPasswordForm] = useState<ForgotPasswordPayload>({
    email: "",
  })

  const handleFormChange = (field: keyof typeof forgotPasswordForm) => (e: ChangeEvent<HTMLInputElement>) => {
    setForgotPasswordForm(prev => ({...prev, [field] : e.target.value}))
  }

  async function handleOnSubmit(e: FormEvent){
    e.preventDefault();
    try {
      await dispatch(forgotPassword(forgotPasswordForm)).unwrap();
      toast.success(t("features.auth.forgot_password.toast.link_success_sent"));
    } catch (error) {
      console.log(error);
      toast.error((error as Error).message)
    }
  }

  return {handleOnSubmit, handleFormChange, forgotPasswordForm}
}