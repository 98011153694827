import { Institution } from "@entities/institution";
import { requestor } from "./requestor";


export async function getAllInstitution(): Promise<{institutions: Institution[]}>{
  return (await requestor.get("institution/")).data;
}

export async function getAllIDRInstitution(): Promise<{institutions: Institution[]}>{
  return (await requestor.get("institution/idr")).data;
}

export async function getAllUSDInstitution(): Promise<{institutions: Institution[]}>{
  return (await requestor.get("institution/usd")).data;
}

