import clsx from "clsx";
import { ComponentPropsWithoutRef, useState } from "react";
import Eye from "@assets/icon/eye.svg?react";
import EyeSlash from "@assets/icon/eye-slash.svg?react";


interface TextInputProps extends Omit<ComponentPropsWithoutRef<"input">, "type"> {
  inputClassName?: string;
} 


export function PasswordInput({className, placeholder, inputClassName, ...props} : TextInputProps){

  const [isVisible, setVisible] = useState(false);

  const toggle = () => {
    setVisible(!isVisible);
  };

  return (
    <>
    <div className={clsx("relative",className)}>
      <input 
        type={isVisible ? "text" : "password"}
        id={props.name}
        name={props.name}
        className={clsx(
          "text-black p-2 pt-4 rounded-sm outline-none border-b-4 border-b-warning", 
          "focus:border-b-primary-light peer placeholder:text-transparent w-full",
          inputClassName
          )} 
        placeholder={" "}
        {...props} 
      />
      <label 
        htmlFor={props.name} 
        className={clsx("absolute text-gray-500 left-0 ml-1 px-1 peer-placeholder-shown:text-gray-700",
          "peer-placeholder-shown:translate-y-[40%] text-sm duration-100 ease-linear",
          "peer-placeholder-shown:text-base peer-focus:ml-1 peer-focus:text-gray-500",
          "peer-focus:-translate-y-0 peer-focus:px-1 peer-focus:text-sm"
        )}
      >
          {placeholder}
      </label>
      <button 
        onClick={() => setVisible(!isVisible)} 
        type="button" 
        className="absolute right-0 text-gray-400 hover:text-secondary-base p-2"
      >
        {isVisible ? <EyeSlash width={30} /> : <Eye width={30} />}
      </button>
    </div>
    </>
  )
}