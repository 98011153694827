import clsx from "clsx"
import { Link } from "react-router-dom"

import HomeIcon from "@shared/assets/icon/home.svg?react";
import ListIcon from "@shared/assets/icon/list.svg?react";
import PlusIcon from "@shared/assets/icon/plus.svg?react";
import DownloadIcon from "@shared/assets/icon/download.svg?react";
import { CreateNewTaskWrapperProps } from "@features/task/new-task";
import { useTranslation } from "react-i18next";

const baseUrl = import.meta.env.VITE_API_HOST;


export function HomeButton() {
  return (
    <Link
      title="Pergi Ke Home" 
      to={"/home"} 
      className={clsx(
        "inline-flex items-center gap-2 px-5 py-1.5 text-gray-200 rounded-full bg-secondary-dark border",
        "border-secondary-dark hover:border-blue-600 hover:bg-gray-900 active:bg-gray-800"
      )}
    >
      <HomeIcon className="" width={20} height={20} /> 
      <p className="hidden 2xl:block">
        Home
      </p>
    </Link>
  )
}

export function ProgressButton() {
  return (
  <Link 
    title="Pergi Ke Progress" 
    to={"/progress"} 
    className={clsx(
      "inline-flex items-center gap-2 px-5 py-1.5 text-gray-200 rounded-full bg-secondary-dark ",
      "border border-secondary-dark hover:border-blue-600 hover:bg-gray-900 active:bg-gray-800")
    }
  >
    <ListIcon className="" width={20} height={20} /> 
    <p className="hidden 2xl:block">
      Progress
    </p>
  </Link>
  )
}

export function CreateNewTaskButton() {

  const {t} = useTranslation();

  return (
  <CreateNewTaskWrapperProps>
    <button 
      title="Buat Task Baru" 
      className={clsx(
        "inline-flex items-center gap-2 px-5 py-1.5 text-gray-200 rounded-full bg-secondary-dark border",
        " border-secondary-dark hover:border-blue-600 hover:bg-gray-900 active:bg-gray-800"
      )}>
      <PlusIcon className="" width={20} height={20} /> 
      <p className="hidden 2xl:block">
        {/* New Task */}
        {t("widgets.main_navbar.reanalisis")}
      </p>
    </button>
  </CreateNewTaskWrapperProps>
  )
}

export function DownloadButton () { 
  return (
    <a 
      title="Download Aplikasi windows" 
      className={clsx(
        "inline-flex items-center gap-2 px-5 py-1.5 text-gray-200 rounded-full bg-secondary-dark",
        " border border-secondary-dark hover:border-blue-600 hover:bg-gray-900 active:bg-gray-800"
      )} 
      href={baseUrl + "/download-client-app"} 
      target="_blank"
    >
      <DownloadIcon className="" width={20} height={20} />
      <p className="hidden 2xl:block">
        Use Window App
      </p>
  </a>
  )
}