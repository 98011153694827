import SupriseIllustration from "@assets/ilustration/suprise-box.png";
import { OutlineButton } from "@shared/components/form";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { GIFT_REDIRECT } from "../../model/const";


interface SurveyClaimGiftProps {
  onClose: () => any;
}


export function SurveyClaimGift(props: SurveyClaimGiftProps){



  return (
    <div className="px-10 py-8">
      <img src={SupriseIllustration as string} className="mx-auto" />
      <h1 className="text-4xl mb-3 font-semibold text-warning text-center">Selamat !!</h1>
      <p className="text-center mb-3">
        Anda berhasil <span className="text-warning"> mendapat diskon decrypt 10% </span>, terus mengisi untuk mendapat hadiah selanjutnya!! 
        Semoga ini menjadi awal dari pengalaman yang lebih menyenangkan bersama layanan kami!
      </p>
      <div className="flex justify-center gap-2">
        <OutlineButton onClick={props.onClose} variant="primary">Kembali</OutlineButton>
        {/* <SolidButton >Klaim</SolidButton> */}
        <a
          className="bg-primary hover:bg-primary/80 active:bg-primary/70 py-3 px-12 rounded transition" 
          href={GIFT_REDIRECT}
          target="_blank" 
          rel="noopener noreferrer"
        >
          Klaim
        </a>

      </div>
    </div>
  )
}