import { cn } from "@shared/utils/tailwind-merge";
import { ReactElement, ReactNode } from "react"


export interface TimeLineData {
  PointElement?: ReactNode;
  description?: ReactNode;
}

interface SurveyTimeLineProps {
  activeTimeLine: number;
  timeLineData: TimeLineData[];
}

/** impired from chat gtp */
export function SurveyTimeLine(props: SurveyTimeLineProps){

  return (
    <div className="flex items-center justify-center  overflow-x-auto py-4">
      {props.timeLineData.map((item, index) => {
        if(index  === props.timeLineData.length -1) return (
          <div  
            key={index}
            style={{ width: "120px"}}
            className="relative flex flex-col items-center text-center"
          >
            <div className={cn(
              "w-10 h-10 rounded-full realtive z-10  bg-gray-300",
              "text-black  flex items-center justify-center",
              index < props.activeTimeLine && "bg-blue-500",
              index === props.activeTimeLine && "border-2 border-blue-500 "
            )}>
              {item.PointElement}
            </div>
            <p className="mt-2 text-sm">{item.description}</p>
          </div>
        )

        

        return (
          <div  
            key={index}
            style={{ width: "120px"}}
            className="relative flex flex-col items-center text-center"
          >
            <div className={cn(
              "w-10 h-10 rounded-full realtive z-10  bg-gray-300",
              " text-black flex items-center justify-center",
              index < props.activeTimeLine && "bg-blue-500 text-white",
              index === props.activeTimeLine && "border-4 border-blue-500 shadow-2xl shadow-white"
              )}
            >
              {item.PointElement}
            </div>
            <hr 
              style={{ width: "120px"}}
              className={cn(
                "border-none z-0 w-32 h-1 bg-gray-300 mt-2 absolute ",
                "left-1/2 right-1 bottom-1/2 -translate-y-3 ",
                index < props.activeTimeLine && "bg-blue-500"
              )}
            />
          <p className="mt-2 text-sm">{item.description}</p>
      </div>
        )
      })}

    </div>

  )
}