import { createAsyncThunk } from "@reduxjs/toolkit";
import { SelectAnalystPayload } from "./type";
import { selectAnalystTypeService } from "@services/www.fixransomware.com";






export const selectAnalystTypeAction = createAsyncThunk(
  "currentTask/selectAnalystType",
  async (payload: SelectAnalystPayload, {getState}) => {
    const {currentTask} = getState() as {
      currentTask: {task:{id: string}}
    };

    const data = {
      taskAnalystType: payload.taskAnalystType,
      taskId: currentTask?.task?.id
    }
    const result = await selectAnalystTypeService(data);

    if(!result.data) throw new Error("fail to set analyst Type");

    return result.data;
  }
)