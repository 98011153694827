import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CurrentSurveySliceState, FulfilledAction, FulfilledIsAlreadyAnsweredAction, PendingAction, RejectedAction } from "./type";







const initialState : CurrentSurveySliceState = {
  loading: false,
  error: "",
  isAlreadyAnswered: false,
}

export const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    setSurveyIsAlreadyAnsewer: (state,action: PayloadAction<boolean>) => {state.isAlreadyAnswered = action.payload}
  },

  // # TODO: refactor later, for now it's doesn't to work with so many api
  extraReducers : builder => {

    builder.addMatcher(
      (action): action is RejectedAction => action.type.endsWith("/rejected") && action.type.startsWith("survey"),
      (state, action) => {
        state.loading = false;
        state.error = action.error;
      }
    );

    builder.addMatcher(
      (action): action is PendingAction => action.type.endsWith("/pending") && action.type.startsWith("survey"),
      (state) => {
        state.loading = true;
      }
    );

    builder.addMatcher(
      (action): action is FulfilledAction => action.type.endsWith("/fulfilled") && action.type.startsWith("survey"),
      (state, action) => {
        state.loading = false;
      }
    );

    builder.addMatcher(
      (action): action is FulfilledIsAlreadyAnsweredAction => action.type.endsWith("/isAlreadyAnswer/fulfilled") 
        && action.type.startsWith("survey"),
      (state, action) => {
        state.loading = false;
        state.isAlreadyAnswered = action.payload;
      }
    )
  },
})


export const selectSurveyState = (state: RootState) => state.survey;


export const {setSurveyIsAlreadyAnsewer} = surveySlice.actions;