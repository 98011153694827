import { useEffect, useState } from "react";
import { taskGetAnalystPreview } from "@services/www.fixransomware.com";
import { Task } from "@entities/task";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { PaymentAnalystPreviewButton } from "./PaymentAnalystPreviewButton";
import clsx from "clsx";
import { useTranslation } from "react-i18next";


interface AnalystPreview {
  task: Task
}

export function AnalystPreview({task} : AnalystPreview){

  const [imgSrc, setImgSrc] = useState<string | null>(null);

  const { t } = useTranslation();

  useEffect(()=>{
    if(!task.id) return;

    if(!task?.analystPreviewData) return;

    taskGetAnalystPreview(task.id).then(result => {setImgSrc(result)});

    
  },[task.id])

  if(!task.analystPreviewData) return "";

  return(
    <>
      <div className="bg-secondary-base rounded-lg px-3 py-2 mt-1">
        <div className="flex justify-between items-center">
          <h3 className="text-white font-semibold">{t("features.task.analyst_preview_payment.title")}</h3>
          <ButtonPay task={task}  />
        </div>
        {imgSrc && (
          <img 
            className={clsx(
              "transition rounded-lg mt-3 mx-auto",

              // blur when status is not success
              !(task?.analystPreviewData?.analystPreviewPaymentStatus === "Success") && "blur-xl hover:blur-lg"
            )} 
            src={imgSrc} 
          />
        )}
      </div>
    </>
  )
}

function ButtonPay(props: {task: Task}){
  const { t } = useTranslation();
  switch (props.task?.analystPreviewData?.analystPreviewPaymentStatus) {
    case "Init":
      return <PaymentAnalystPreviewButton />
  
    case "Pending":
      return (
        <SolidButton variant="primary" disabled>
          {t("features.task.analyst_preview_payment.waiting_confirmation_button_text")}
        </SolidButton>
        )
    
    case "Success":
      return (
      <a href={props.task?.analystPreviewData?.analystPreviewLink} target="_blank" rel="noopener noreferrer">
        <SolidButton variant="warning">
          Download File 
        </SolidButton>
      </a>
      )

    default:
      return ""
  }
}