import { selectCurrentStage } from "@entities/task";
import { TaskProcessStatus } from "../task-process-const";




export function activeLocalStagebyStage(currentStage: ReturnType<typeof selectCurrentStage>){
  switch(currentStage){

    case "EMPTY":
      return "empty";
      break;

    case "INDEXING":
      return ("indexing_and_virus_upload");
      break;
    
    case "UPLOADED_HACKER_NOTE_AND_SAMPLE_FILE":
    case "ANALYST_PAYMENT_CONFIRM":
    return ("hacker_note_and_sample_upload");
      break;
    
    case "ANALSYT_PAYMENT_ACTION" :
    case "ANALYST_PAYMENT_INIT"   :
    case "ANALYST_RESULT"         :
      return ("analyst");
      break;
    
    case "DECRYPT_PAYMENT_INIT"    :
    case "DECRYPT_PAYMENT_ACTION"  :
    case "DECRYPT_PAYMENT_CONFIRM" :
    case "DECRYPT_ESTIMATION"      :
    case "DECRYPT_RESULT"          :
      return ("decrypt");
      break;
    
    default:
      return null
      break;
  }
}

export function activeLocalStagebyStageEnum(currentStage: ReturnType<typeof selectCurrentStage>){
  switch(currentStage){

    case "EMPTY":
      return TaskProcessStatus.EMPTY;
      break;

    case "INDEXING":
      return TaskProcessStatus.INDEXING_AND_VIRUS_UPLOAD
      break;
    
    case "UPLOADED_HACKER_NOTE_AND_SAMPLE_FILE":
      return TaskProcessStatus.HACKER_NOTE_AND_SAMPLE_UPLOAD
      break;
        
    case "SELECTED_ANALYST_TYPE"  :
    case "ANALYST_PAYMENT_CONFIRM":
    case "ANALSYT_PAYMENT_ACTION" :
    case "ANALYST_PAYMENT_INIT"   :
    case "ANALYST_RESULT"         :
    
      return TaskProcessStatus.ANALYST;
      break;
    
    case "DECRYPT_PAYMENT_INIT"    :
    case "DECRYPT_PAYMENT_ACTION"  :
    case "DECRYPT_PAYMENT_CONFIRM" :
    case "DECRYPT_ESTIMATION"      :
    case "DECRYPT_RESULT"          :
      return TaskProcessStatus.DECRYPT;
      break;
    
    default:
      return null
      break;
  }
}