import { SolidButton } from "@shared/components/form/button/SolidButton";



interface ChangeActiveStatusButtonProps {
  onClick: () => any;
  label: string
}

export function ChangeActiveStatusButton(props: ChangeActiveStatusButtonProps){
  return (
    <SolidButton onClick={() => props.onClick()} className="px-2 w-full" variant="warning">{props.label}</SolidButton>
  )
}