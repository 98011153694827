import { UserProfileCard } from "@features/auth/profile";






export function Profile(){


  return (
    <div className="max-w-[600px] mx-auto h-full flex flex-col justify-center">
      <UserProfileCard />
    </div>
  )
}