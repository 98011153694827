import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { selectCurrentStage, selectTask, selectTaskCheckup, TaskAnalystType } from "@entities/task";
import { selectIfOnlyVirusNameThatPresent } from "@entities/virus";
import { PaymentAnalystButton } from "@features/task/analyst-payment";
import { PaymentDecryptButton } from "@features/task/decrypt-payment";
import { CardPreview } from "@shared/components/card";
import { CircleProgress } from "@shared/components/progress";
import { useAppSelector } from "@shared/model/hooks";
import { AnalystProcess } from "@process/task-process/stage/analyst-process";
import { DecryptProcess } from "@process/task-process/stage/decrypt-process/DecryptProcess";
import { activeLocalStagebyStageEnum } from "../lib/utils";
import { LocalCardWrapper } from "../components/LocalCardWrapper";
import { ProcessVirusAndIndex } from "../stage/indexing-and-virus-process";
import { useTranslation } from "react-i18next";
import { ChangeActiveStatusButton } from "../components/changeActiveStatusButton";
import { TaskProcessStatus } from "../task-process-const";
import { SampleAndHackerNoteProcess } from "../stage/sample-and-hacker-note-process";
import { SelectAnalystTypeProcess } from "../stage/empty/SelectAnalystTypeProcess";
import { VirusSelectprocess } from "../stage/empty/VirusSelectProcess";
import { SurveyButton } from "@features/survey/take-survey";
import { FreeAnalystSurveyButton } from "@features/task/take-survey-for-analyst-term";
import { SampleAndHackerNoteForm } from "../stage/sample-and-hacker-note-process/SampleAndHackerNoteForm";
import { SelectNoteAndSampleModal } from "@features/task/select-note-and-sample";



/**
 * this component is only used for Task page in proigress.
 * the difference is only about sample uploading.
 * 
 * the loading state and stage value that resulted from selector didn't match in update.
 * when loading state is finished, the stage value still in unupdated state for a while.
 * so the user will saw a glimpse of malformed  view. 
 * 
 * @todo 
 * 
 */
export function TaskProcessProgress(){
  const isGettingStageLoading = useAppSelector(state => state?.currentTask?.loading);
  const analystTypeExists = useAppSelector(state => state?.currentTask?.activeAnalystType);
  const isTaskAlreadyChecked = useAppSelector(selectTaskCheckup);
  const isTaskIdExists = useAppSelector(state => !!state?.currentTask?.task?.id);

  const currentStage = useAppSelector(selectCurrentStage);
  const isVirusOnlyName = useAppSelector(selectIfOnlyVirusNameThatPresent);
  const currentPercent = useAppSelector(state => Number(state?.currentTask?.task?.percentage ?? 0));
  const [activeStatus, setActiveStatus] = useState< TaskProcessStatus | null>(null);

  const currectStatus = useMemo(() => activeLocalStagebyStageEnum(currentStage),[currentStage])

  const task = useAppSelector(selectTask);

  const [showStep, setShowStep] = useState(false);

  const {t} = useTranslation();


  useEffect(()=>{
    console.log("load state", isGettingStageLoading )
    if(isGettingStageLoading) return;
    const current = activeLocalStagebyStageEnum(currentStage);
    console.log("current : " + current);
    if(!current) return;
    setActiveStatus(current)
  },[currentStage, isGettingStageLoading])

  // check if virus name already provided
  useEffect(() => {
    // console.log(isVirusOnlyName)
    if(isVirusOnlyName) setActiveStatus(TaskProcessStatus.INDEXING_AND_VIRUS_UPLOAD);
  }, [isVirusOnlyName])

  function setDoneStatusByActiveStatus(targetStatus: TaskProcessStatus){
    if(currectStatus === null) return "pending"
    if(currectStatus < targetStatus ) return "pending";
    if(currectStatus > targetStatus) return "done";
    if(currectStatus === targetStatus ) return "active";
    return "pending";
  }

  function handleSelectVirus(virusName: string){
    setActiveStatus(TaskProcessStatus.INDEXING_AND_VIRUS_UPLOAD);
  }

  if(isGettingStageLoading) return ""

  // if(
  //   ( activeStatus === TaskProcessStatus.EMPTY) 
  //   && isTaskAlreadyChecked 
  //   && !isGettingStageLoading ){
  //   // return <SelectAnalystTypeProcess />
  //   return <SampleAndHackerNoteForm onSelectVirus={handleSelectVirus}  />
  // }
  
  // if(activeStatus === TaskProcessStatus.HACKER_NOTE_AND_SAMPLE_UPLOAD){
  //   return <SelectAnalystTypeProcess />
  // }

  // if(analystTypeExists && activeStatus !== TaskProcessStatus.INDEXING_AND_VIRUS_UPLOAD && !isTaskIdExists){
  //   return <VirusSelectprocess />
  // }
  
  // if(activeStatus === TaskProcessStatus.EMPTY && isTaskAlreadyChecked && !isGettingStageLoading ){
  //   return <SelectAnalystTypeProcess />
  // }

  if(activeStatus === TaskProcessStatus.HACKER_NOTE_AND_SAMPLE_UPLOAD){
    return <SelectAnalystTypeProcess />
  }


  return (
    <section className="px-28 py-10">
    <p className="mb-10 text-gray-500">
      {t("process.task_process.task_process.header")} 
    </p>
    <div className="flex justify-center items-center">
      <div className="sticky self-start">
        <div className="p-20 xl:p-32 flex flex-col">
          <CircleProgress percentage={currentPercent} />
          {( currentStage === "ANALSYT_PAYMENT_ACTION")  && <SurveyButton />}
          {/* {currentStage} - {activeStatus} */}
          {(currentStage === "ANALYST_PAYMENT_INIT") ? (task.analystType !== TaskAnalystType.FREE)
            ? <PaymentAnalystButton />
            : <FreeAnalystSurveyButton />
            : null
          }
          {currentStage === "DECRYPT_PAYMENT_INIT" && <PaymentDecryptButton />}
          {currentStage === "INDEXING" && <SelectNoteAndSampleModal />}
        </div>
      </div>
      {!activeStatus && (
        <div className="flex flex-col gap-2 justify-center w-full">
          <motion.div layoutId="indexing-layout">
            <CardPreview
              onClick={() => setActiveStatus(TaskProcessStatus.INDEXING_AND_VIRUS_UPLOAD)}
              className="cursor-pointer"
              title={t("process.task_process.task_process.process.indexing_virus_upload.title")}
              status={setDoneStatusByActiveStatus(TaskProcessStatus.INDEXING_AND_VIRUS_UPLOAD)}
              statusDescription={t("process.task_process.task_process.process.indexing_virus_upload.description")}
            />
          </motion.div>
          <motion.div layoutId="sample-hacker-layout">
            <CardPreview
              onClick={() => setActiveStatus(TaskProcessStatus.HACKER_NOTE_AND_SAMPLE_UPLOAD)}
              className="cursor-pointer"
              title={t("process.task_process.task_process.process.sample_and_hacker_note.title")}
              status={setDoneStatusByActiveStatus(TaskProcessStatus.HACKER_NOTE_AND_SAMPLE_UPLOAD)}
              statusDescription={t("process.task_process.task_process.process.sample_and_hacker_note.description")}
            />
          </motion.div>
          <motion.div layoutId="analyst-layout">
            <CardPreview
              onClick={() => setActiveStatus(TaskProcessStatus.ANALYST)}
              className="cursor-pointer"
              title={t("process.task_process.task_process.process.analyst_result.title")}
              status={setDoneStatusByActiveStatus(TaskProcessStatus.ANALYST)}
              statusDescription={t("process.task_process.task_process.process.analyst_result.description")}
            />
          </motion.div>
          <motion.div layoutId="decrypt-layout">
            <CardPreview
              onClick={() => setActiveStatus(TaskProcessStatus.DECRYPT)}
              className="cursor-pointer"
              title={t("process.task_process.task_process.process.decryption.title")}
              status={setDoneStatusByActiveStatus(TaskProcessStatus.DECRYPT)}
              statusDescription={t("process.task_process.task_process.process.decryption.description")}
            />
          </motion.div>
        </div>
      )}
      <AnimatePresence>
      {activeStatus === TaskProcessStatus.INDEXING_AND_VIRUS_UPLOAD && (
        <LocalCardWrapper layoutId="indexing-layout">
          <ProcessVirusAndIndex />
        </LocalCardWrapper>
      )}
      {/* {activeStatus === TaskProcessStatus.HACKER_NOTE_AND_SAMPLE_UPLOAD && (
        <LocalCardWrapper layoutId="sample-hacker-layout" type="none">
          <SampleAndHackerNoteProcess />
        </LocalCardWrapper>
      )} */}
      {activeStatus === TaskProcessStatus.ANALYST && (
        <LocalCardWrapper layoutId="analyst-layout">
          <AnalystProcess />
        </LocalCardWrapper>
      )}
      {activeStatus === TaskProcessStatus.DECRYPT && (
        <LocalCardWrapper layoutId="decrypt-layout">
          <DecryptProcess />
        </LocalCardWrapper>
      )}
      </AnimatePresence>
    </div>
  </section>
  )
}



