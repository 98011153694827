import { useAppDispatch } from "@shared/model/hooks";
import { ChangeEvent, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loginByEmailAuth } from ".";
import { GeneralLoginError } from "./types";
import { useTranslation } from "react-i18next";

function toListElement(data: string[]){
  return(
    <ul className=" flex flex-col break-words">
      {data.map(dat => (
        <li className="border-b border-b-red-200 py-1">
          {dat}
        </li>
      ))}
    </ul>
  )
}


export function useLoginByEmailHook(){
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: ""
  })

  const handleFormChange = (field: keyof typeof loginForm) => (e: ChangeEvent<HTMLInputElement>) => {

    let value: string;

    if(field === "email")
      value = e.target.value.toLowerCase();
    else
      value = e.target.value;

    setLoginForm(prev => ({...prev, [field] : value}))
  }

  async function handleOnSubmit(e: FormEvent){
    e.preventDefault();
    try {
      await dispatch(loginByEmailAuth(loginForm)).unwrap();
      navigate("/home");
      toast.success(t("features.auth.login_by_email.toast.success_login"));
    } catch (error) {
      try {
        const errorValue = error as GeneralLoginError;
        toast.error(
          Array.isArray(errorValue.message) 
            ? toListElement(errorValue.message)
            : errorValue.message
        )
      } catch (error) {
        console.log(error);
        toast.error(t("features.auth.login_by_email.toast.failed_login"))
      }
    }
  }

  return {handleOnSubmit, handleFormChange, loginForm}
}