// import { ScanDesktop } from "@features/scan/scan-directory";
import { resetVirus } from "@entities/virus";
import { useSetupCurrentTask } from "@features/task/setup-current";
import { TaskProcessFullStep } from "@process/task-process/task-process/TaskProcessFullStep";
import { useAppDispatch } from "@shared/model/hooks";
import { FreshCreateTask } from "@widgets/fresh-create-task";
import { useEffect } from "react";
 


export function Home(){
  const dispatch = useAppDispatch();
  useSetupCurrentTask();

  useEffect(()=>{
    dispatch(resetVirus())
  },[])

  // return <FreshCreateTask />

  return <TaskProcessFullStep />
  // return <ScanDesktop />
}