import { selectCurrentStage } from "@entities/task";
import { SelectNoteAndSample } from "@features/task/select-note-and-sample";
import { UploadIndexFile } from "@features/virus/select-virus";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { useAppSelector } from "@shared/model/hooks";
import { useTranslation } from "react-i18next";


interface SampleAndHackerNoteFormProps {
  // onShowTimeLine: () => any
  onSelectVirus?: (virusName: string) => any;
}

export function SampleAndHackerNoteForm(props: SampleAndHackerNoteFormProps){
  const activeStage = useAppSelector(selectCurrentStage);
  const {t} = useTranslation();
  
  return (
  <div className="flex justify-center gap-9 xl:gap-28 items-center h-full xl:h-[80%]">
    <div className="flex flex-col">
      <UploadIndexFile onSelectVirus={props.onSelectVirus} isDisable={activeStage !== "EMPTY"}  />
    </div>
    <div className="">
    {/* <div className="flex flex-row-reverse">
      <SolidButton variant="success" onClick={() => props.onShowTimeLine()}>Time Line</SolidButton>
    </div> */}
      <div className="mb-9">
        <h1 className="text-4xl font-bold">Decrypt</h1>
        <p className="text-gray-500">
          {t("widgets.fresh_create_task.instruction")}
        </p>
      </div>
      <SelectNoteAndSample />
    </div>
  </div>
  )
}

