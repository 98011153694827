import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AnalystType, CurrentTaskSliceState, FulfilledAction, PendingAction, RejectedAction } from ".";


const initialState : CurrentTaskSliceState = {
  error: "",
  loading: false,
  // task : {
  //   id: ""
  // }
  task: {},
  checkUp: false,
  activeAnalystType: undefined
}



export const currentTaskSlice = createSlice({
  name: "currentTask",
  initialState,
  reducers: {
    clearTask: state => {state.task = {}},
    setOnCheckUpTask: state => {state.checkUp = true},
    setOffCheckUpTask: state => {state.checkUp = false},
    setActiveAnalystType: (state, action: PayloadAction<AnalystType>) => { state.activeAnalystType = action.payload },
    clearActiveAnalystType: (state) => state.activeAnalystType = undefined, 
  },
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is RejectedAction => action.type.endsWith("/rejected") && action.type.startsWith("currentTask"),
      (state, action) => {
        state.loading = false;
        state.error = action.error;
      }
    );
    builder.addMatcher(
      (action): action is PendingAction => action.type.endsWith("/pending") && action.type.startsWith("currentTask"),
      (state) => {
        state.loading = true;
      }
    )
    builder.addMatcher(
      (action): action is FulfilledAction => action.type.endsWith("/fulfilled") && action.type.startsWith("currentTask"),
      (state, action) => {
        state.loading = false;
        state.task = action.payload
      }
    )
  }
})

export const {
  clearTask, 
  setOffCheckUpTask, 
  setOnCheckUpTask, 
  setActiveAnalystType, 
  clearActiveAnalystType
} = currentTaskSlice.actions