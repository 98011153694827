import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";
import LoaderIcon from "@assets/icon/loader.svg?react"
import { cn } from "@shared/utils/tailwind-merge";
 


interface OutlineButtonProps extends ComponentPropsWithoutRef<"button"> {
  /**
 * use custom to remove all default style value, so you define it's value with your style
 */
  variant?: "success" | "primary" | "warning" | "danger" | "custom" | "default"
  isLoading?: boolean;
}



export function OutlineButton({variant = "default", disabled,className, isLoading, ...props}: OutlineButtonProps){
  return (
    <button
      className={cn(
        "px-12 py-1 rounded border-2 border-white transition",
        className,
        variant === "default" && !disabled && "hover:bg-white hover:text-primary",
        variant === "primary" && !disabled && " hover:bg-primary/80 active:bg-primary/70 border-primary text-primary hover:text-white",
        variant === "success" && !disabled && " hover:bg-success-dark/80 active:bg-success-dark/70",
        variant === "warning" && !disabled && " hover:bg-warning/80 active:bg-warning/70 text-black",

        variant === "primary" && disabled && "bg-primary/50",
        variant === "success" && disabled && "bg-success-dark/50",
        variant === "warning" && disabled && "bg-warning/50",
        disabled && "cursor-not-allowed"
      )}
        {...props}
    >
      <div className="flex justify-center gap-1 items-center">
        {isLoading && <LoaderIcon width={20} height={20} className="animate-spin" />}
        {props.children}
      </div>
    </button>
  )
}