import { DialogWithHeader } from "@shared/components/dialog";
import { DefaultDropzone } from "@shared/components/form";
import { SolidButton } from "@shared/components/form/button/SolidButton";
import { ComponentPropsWithoutRef } from "react";
import { FileWithPath } from "react-dropzone";
import { MAX_FILE_PROOF_COUNT, MAX_FILE_PROOF_SIZE } from "../constant";
import { useTranslation } from "react-i18next";


interface PaymentAnalystPreviewSelectModalProps extends Omit<ComponentPropsWithoutRef<typeof DialogWithHeader>, "title">{
  filesValue: FileWithPath[];
  setFilesValue: React.Dispatch<React.SetStateAction<FileWithPath[]>>;
  getSendFileModalOpen: ()=> any;
}


export function PaymentAnalystPreviewSelectModal({filesValue, setFilesValue, isOpen, getSendFileModalOpen, modalAccess, /* ...props */}: PaymentAnalystPreviewSelectModalProps){

  const { t } = useTranslation();

  function handleSelectFile (){
    modalAccess(false);
    getSendFileModalOpen();
  }

  return (
    <DialogWithHeader
      isOpen={isOpen}
      modalAccess={modalAccess}
      // title="Upload Bukti Transfer disini"
      title={t("features.task.analyst_preview_payment.modal.title")}
      className="bg-secondary-base basis-1/3 max-h-[80%] overflow-auto"
    >
      <div className="p-2">
        <DefaultDropzone
          filesValue={filesValue}
          setFilesValue={setFilesValue}
          whenDragText={t("features.task.analyst_preview_payment.modal.when_drag")}
          whenIdleText={t("features.task.analyst_preview_payment.modal.when_idle")}
          // whenDragText={"Jatuhkan File anda di bawah"}
          // whenIdleText={"klik atau drag file anda disini, ukuran maksimal file adalah 10 mb dengan jumlah 1 buah"}
          maxCount={MAX_FILE_PROOF_COUNT}
          maxSize={MAX_FILE_PROOF_SIZE} // sekitar 10 mb
        />
        <SolidButton onClick={handleSelectFile} variant="success" className="mt-3 block ms-auto">
        {t("features.task.analyst_preview_payment.modal.submit_button")}
        </SolidButton>
      </div>
    </DialogWithHeader>
  )
}