import { CardWithDate } from "@shared/components/card";
import { useEffect, useMemo, useState } from "react";
import { VirusView } from "./VirusView";
import { IndexingView } from "./IndexingView";
import { useAppSelector } from "@shared/model/hooks";
import { selectCurrentStageIndexing, selectTask } from "@entities/task";
import { SuccessView } from "./SuccessView";
import { IndexingSuccess } from "@entities/task/stage/indexing";
import { useTranslation } from "react-i18next";
import { Virus } from "@entities/virus";
import { getVirusById } from "@services/www.fixransomware.com";
import i18next from 'i18next'




export function ProcessVirusAndIndex(){
  const [localStage, setLocalStage] = useState<"virus-view" | "indexing-view" | "success-view">("virus-view");
  // const [virusData, setVirusData] = useState<Virus | null>(null);

  const isAlreadyIndexing = useAppSelector(selectCurrentStageIndexing);
  const virus = useAppSelector(state => state.currentVirus.virus)
  const task = useAppSelector(selectTask);
  
  const { t } = useTranslation();

  // useEffect(() => {setVirusDataValue()},[virus?.id, task])

  // async function setVirusDataValue(){
  //   console.log("run to get virus");
  //   if(!task) { setVirusData(null); return }
  //   if(virus?.id){ setVirusData(virus); return }

  //   if(!task.virus) { setVirusData(null);return;}

  //   console.log("before reqesting virus")
  //   const virusResult = await getVirusById(task.virus);
  //   if(!virusResult) {setVirusData(null); return}

  //   console.log(virusResult)
  //   setVirusData(virusResult.data);
    
  // }

  useEffect(()=>{
    console.log("is already indexing : " + isAlreadyIndexing);
    console.log(localStage);
    if(isAlreadyIndexing) setLocalStage("success-view");
  },[isAlreadyIndexing])

  return (
    <>
      <CardWithDate 
        title={t("process.task_process.indexing_virus_process.title")}
        date={new Date()}
        className="w-full h-full"
      >
        <>
          {localStage === "virus-view" && <VirusView moveToIndexingView={()=>setLocalStage("indexing-view")} />}
          {localStage === "indexing-view" && <IndexingView />}
          {localStage === "success-view" && <IndexingSuccess task={task} virus={i18next.resolvedLanguage === "id" ? virus : undefined } />}
        </>
      </CardWithDate> 
    </>
  )
}