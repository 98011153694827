import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMySurveyAnswer } from "@services/www.fixransomware.com/survey";






export const checkIfSurveyAlreadyAnswered = createAsyncThunk(
  "survey/isAlreadyAnswer",
  async () => {
    const result = await getMySurveyAnswer();
    if(result?.length) return true;
    return false;
  }
 )