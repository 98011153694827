import { SurveyOptionType } from "@entities/survey/model/content.type";
import { requestor } from "./requestor";
import { FixransomwareResponse } from "@shared/types/http";
import { Task } from "@entities/task";






export async function getMySurveyAnswer(){
  const result = await requestor.get("survey/answer/me");
  return  result.data;
}



export async function getSurveyContent(surveyId: string){
  const result = await requestor.get("survey/content/" + surveyId);
  return result.data;
}

export async function getSurveyByType(surveyType: string){
  const result = await requestor.get("survey/content/type/" + surveyType);
  return result.data;
}

export interface SurveyAnswerPayload {
  taskId: string ;

  answer: {
    questionId: string;
    answerId: string;
    answerType: "text" | "input";
    answerValue: string;
  }[];
}

export async function answerSurvey(surveyContentId: string, answerPayload: SurveyAnswerPayload){

  const result = await requestor.post("survey/answer/" + surveyContentId + "/submit", answerPayload);
  return  result.data;
}


export interface answerAnalystQualificationSurveyPayload {
  taskId: string ;

  answer: {
    questionId: string;
    answerId: string;
    answerType: "text" | "input";
    answerValue: string;
  }[];
}

export async function answerAnalystQualificationSurvey(surveyContentId: string, answerPayload: answerAnalystQualificationSurveyPayload){

  const result = await requestor.post<FixransomwareResponse<Task>>("survey/answer/" + surveyContentId + "/submit-free-task", answerPayload);
  return  result.data;
}