import { User, selectUser, selectUserCheckUp, setOnCheckUp, setUser } from "@entities/user";
import { getToken } from "@services/localStorage";
import { checkAuthService, setRequestToken } from "@services/www.fixransomware.com";
import { useAppDispatch, useAppSelector } from "@shared/model/hooks";
import jwtDecode from "jwt-decode";
import { useLayoutEffect } from "react";
import i18next from 'i18next'




/**
 * setup user global state from local token.
 * check if this app is authenticated.
 * 
 */
export function useSetupUser(){
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const setUpStatus = useAppSelector(selectUserCheckUp);


  async function setupUser(){
    try {
      if(setUpStatus) return;
      const userToken = getToken();
      if(!userToken){
        dispatch(setOnCheckUp());
        return;
      }
  
      // check if token is valid and get newest user data
      // todo: make request to check if token is valid
      const result = await checkAuthService({token: userToken});


      const decoded = jwtDecode<{user: User}>(userToken);
      setRequestToken(userToken); // set global request and response token
      
      // when user aren't set in state
      if(!user) dispatch(setUser(result.data.client));

      // set language preference for user
      const lang = result.data.client?.extra?.language;
      // console.log("bahasa : " + lang);
      if(lang) i18next.changeLanguage(lang);

      dispatch(setOnCheckUp())
    } catch (error) {
      console.error(error);
      dispatch(setOnCheckUp())
    }
  }

  // setup user from local storage
  // run this process before all effect in app
  useLayoutEffect(()=>{ setupUser() },[])
  
}