import { AsyncThunk } from "@reduxjs/toolkit"
import { SurveyContent } from "./content.type";


export enum SurveyContentType {
  ACCELERATION_SAMPLE_ANALYST = "acceleration-sample-analyst",
  ACCELERATION_SAMPLE_ANALYST_EN = "acceleration-sample-analyst",
  FREE_ANALYST_QUALIFICATION = "free-analyst-qualification",
  FREE_ANALYST_QUALIFICATION_EN = "free-analyst-qualification-en",
}


export type SurveyContentAsyncThunk = AsyncThunk<SurveyContent, string, any>;
export type PendingAction = ReturnType<SurveyContentAsyncThunk["pending"]>;
export type RejectedAction = ReturnType<SurveyContentAsyncThunk["rejected"]>;
export type FulfilledAction = ReturnType<SurveyContentAsyncThunk["fulfilled"]>;

export type SurveyIsAlreadyAnsweredAsyncThunk = AsyncThunk<boolean, string, any>;
export type FulfilledIsAlreadyAnsweredAction = ReturnType<SurveyIsAlreadyAnsweredAsyncThunk["fulfilled"]>;


export type CurrentSurveySliceState = {
  loading: boolean;
  error: any;
  isAlreadyAnswered: boolean;
  // checkUp: boolean;
}
